package pixelpark.theme

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.size
import org.jetbrains.compose.web.css.px

data class FontFamily(val families: List<String>)
data class TextStyle(val size: Int, val lineHeight: Int? = null, val fontFamily: FontFamily? = null, val fontWeight: FontWeight? = null)

fun Modifier.textStyle(textStyle: TextStyle) = this
    .size(textStyle.size.px)
    .let {
        if (textStyle.fontWeight != null) {
            Modifier.fontWeight(textStyle.fontWeight)
        } else {
            Modifier
        }
    }
    .let {
        if (textStyle.lineHeight != null) {
            Modifier.lineHeight(textStyle.lineHeight)
        } else {
            Modifier
        }
    }
    .let {
        if (textStyle.fontFamily != null) {
            Modifier.fontFamily(*textStyle.fontFamily.families.toTypedArray())
        } else {
            Modifier
        }
    }