package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.marginBlock
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H4

@Composable
fun RandomMessageLoadingIndicator(modifier: Modifier = Modifier) {
    val loadingMessages = listOf(
        "Halen logrer..." to "Vi finder de perfekte hundeskove til dig! 🐕🌲",
        "Graver efter skovene..." to "Snart har vi fundet de bedste hundeskove! 🦴🌳",
        "Eventyr venter!" to "Snart klar til at sende dig og din hund på eventyr i skoven! 🐶🌲",
        "Henter pinde..." to "Dine hundeskove er lige om hjørnet! 🐾🌿",
        "Snuderne i sporet!" to "Vi finder de bedste skove til dig! 🐕‍🦺🍃"
    )

    val message = remember { loadingMessages.random() }

    ScreenLoadingIndicator(message.first, message.second, modifier)
}

@Composable
fun ScreenLoadingIndicator(
    title: String? = null,
    message: String? = null,
    modifier: Modifier = Modifier,
) {
    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(16.px),
        modifier = modifier
            .fillMaxWidth()
            .padding(32.px),
    ) {

        PawLoader()

        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.spacedBy(8.px),
        ) {

            if (title != null) {
                H4(Modifier.marginBlock().toAttrs()) {
                    SpanText(
                        text = title,
                        modifier = Modifier.textAlign(TextAlign.Center),
                    )
                }
            }
            if (message != null) {
                SpanText(
                    text = message,
                    modifier = Modifier.textAlign(TextAlign.Center),
                )
            }
        }
    }
}

