package pixelpark.model

import kotlinx.serialization.Serializable

@Serializable
data class ParkMapsPoint(
    val parkId: String = "",
    val placeId: String = "",
    val title: String = "",
    val stars: Double? = null,
    val ratingsCount: Int? = null,
    val reviews: List<ParkMapsReview> = emptyList(),
    val photoReferences: List<String> = emptyList(),
    val created: Long = 0,
    val updated: Long = 0
)

@Serializable
data class ParkMapsReview(
    val authorName: String = "",
    val authorPhotoUrl: String? = null,
    val text: String = "",
    val rating: Double = 0.0,
    val time: Long = 0L,
    val translated: Boolean = false,
    val language: String? = null,
    val origLanguage: String? = null
)

val ParkMapsReview.authorDisplayName: String get() = this.authorName.split(" ").let { names ->
    val firstName = names.first()
    val lastName = names.last()
        .takeIf { it != firstName }
        ?.firstOrNull()
        ?.let { "$it." }

    return listOf(firstName, lastName).joinToString(separator = " ")
}