package pixelpark.utils

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import kotlinx.browser.window

@Composable
fun Modifier.openNavigationOnClick(latitude: Double, longitude: Double): Modifier {
    val userAgent = window.navigator.userAgent
    val positionQuery = "?q=$latitude,$longitude"
    val iOS = userAgent.contains("iPhone", ignoreCase = true)
            || userAgent.contains("iPad", ignoreCase = true)
            || userAgent.contains("iPod", ignoreCase = true)
    val android = userAgent.contains("android", ignoreCase = true)
    val mapUrl = when {
        iOS -> "maps://$positionQuery"
        android -> "geo:$positionQuery"
        else -> "https://maps.google.com/maps?daddr=$latitude,$longitude"
    }

    return this.onClick {
        window.open(mapUrl, "_system")
    }
}