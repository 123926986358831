package pixelpark.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable

object PixelParkTheme {
    val colorScheme: ColorScheme
        @ReadOnlyComposable
        @Composable
        get() = lightColorScheme()

    val typography: Typography
        @ReadOnlyComposable
        @Composable
        get() = Typography()
}