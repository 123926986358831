package pixelpark.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Img
import pixelpark.PixelParkStrings
import pixelpark.localization.strings
import pixelpark.model.ParkFencing
import pixelpark.model.PopulatedPark

@Composable
fun ParkLocationImage(
    park: PopulatedPark,
    size: Int = 350,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    Box(modifier, contentAlignment = Alignment.Center) {

        val mapsUrl = park.locationImageUrl(size = size)
            ?: // TODO Figure out what to show
            return@Box

        Img(
            src = mapsUrl,
            alt = strings.alt_mapbox,
            attrs = Modifier
                .fillMaxSize()
                .aspectRatio(1f)
                .objectFit(ObjectFit.Cover)
                .toAttrs()
        )

        Img(
            src = park.parkPin(),
            alt = strings.alt_mapbox_pin,
            attrs = Modifier
                .height(50.px)
                .width(36.px)
                .translateY(50.percent * -1)
                .toAttrs()
        )
    }
}

fun PopulatedPark.parkPin(): String {
    return when {
        favourite -> "/ic_map_pin_light_favourite.png"
        features.fencing == ParkFencing.Unfenced -> "/ic_map_pin_default_unfenced.png"
        else -> "/ic_map_pin_default_fenced.png"
    }
}
fun PopulatedPark.locationImageUrl(size: Int = 350, zoom: Int = 13): String? {
    val location = location.coordinates
    val mapsImage = location?.let {
        val lat = it.latitude
        val lng = it.longitude
        val zoomLevel = "$zoom,0"
        val size = "${size}x${size}@2x"
        val token = "pk.eyJ1IjoicG9ja2V0LXZvaWQiLCJhIjoiY2xvYnYwMmljMHN0NzJrcGNoODBrOWZxaSJ9.5k1VgOOblS7MGloxRP94iA"
        val customStyle = "pocket-void/clog2xv3e003k01nz5whn4uqe"
        val outdoorsStyle = "mapbox/outdoors-v12"
        "https://api.mapbox.com/styles/v1/$customStyle/static/$lng,$lat,$zoomLevel/$size" +
                "?access_token=$token"
    }
    return mapsImage
}