package pixelpark.localization

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.rememberPageContext
import pixelpark.DanishStrings
import pixelpark.PixelParkStrings

@Composable
fun strings(): PixelParkStrings {
    val pageContext = rememberPageContext()
    return DanishStrings()
}