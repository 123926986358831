package pixelpark


/**
 * Status of a resource that is provided to the UI.
 *
 */
enum class Status {
    SUCCESS,
    ERROR,
    LOADING
}

/**
 * A generic class that holds a value with its loading status.
 */
data class Resource<out T>(
    val status: Status,
    val data: T?,
    val error: Throwable?,
) {
    companion object {
        fun <T> success(data: T?): Resource<T> {
            return Resource(Status.SUCCESS, data, null)
        }

        fun <T> error(error: Throwable, data: T? = null): Resource<T> {
            return Resource(Status.ERROR, data, error)
        }

        fun <T> loading(data: T? = null): Resource<T> {
            return Resource(Status.LOADING, data, null)
        }
    }
}

// Helper functions for manipulating resources
fun <T> Result<T>?.asResource(): Resource<T> {
    if (this == null) return Resource.loading()
    return this.fold(
        onSuccess = { Resource.success(data = it) },
        onFailure = { Resource.error(error = it) }
    )
}

inline fun <E, T> Resource<T>.transform(transform: (T?) -> E?): Resource<E> {
    return runCatching { Resource(status, transform(data), error) }
        .getOrElse { Resource.error(it) }
}

inline fun <E, T> Resource<T>.transformNotNull(transform: (T) -> E?): Resource<E> {
    return runCatching { Resource(status, data?.let(transform), error) }
        .getOrElse { Resource.error(it) }
}

inline fun <T, R : Any> Resource<List<T>>.mapNotNull(transform: (T) -> R?): Resource<List<R>> {
    return runCatching { Resource(status, data?.mapNotNull(transform), error) }
        .getOrElse { Resource.error(it) }
}

inline fun <T> Resource<List<T>>.filter(predicate: (T) -> Boolean): Resource<List<T>> {
    return Resource(status, data?.filter(predicate), error)
}

inline fun <T> Resource<List<T>>.filterIndex(predicate: (Int, T) -> Boolean): Resource<List<T>> {
    return Resource(status, data?.filterIndexed(predicate), error)
}