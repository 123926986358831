package pixelpark.api

import com.varabyte.kobweb.browser.http.http
import com.varabyte.kobweb.core.AppGlobals
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonConfiguration
import kotlinx.serialization.modules.EmptySerializersModule
import org.w3c.dom.get
import pixelpark.environment
import pixelpark.model.PopulatedPark

object ParksApi {
    private val baseUrl = AppGlobals.environment.baseUrl
    private val json = Json(from = Json.Default) {
        ignoreUnknownKeys = true
    }
    private val cache = sessionStorage

    suspend fun getImageResult(id: String) = kotlin.runCatching { getImage(id) }

    suspend fun getImage(id: String): String {
        val parameters: Map<String, String> = mapOf(
            "id" to id
        )
        val url = buildUrl("image", parameters)
        return window.http.get(url).let { bytes ->
            bytes.decodeToString()
        }
    }

    suspend fun getParkResult(id: String) = kotlin.runCatching { getPark(id) }

    suspend fun getPark(id: String): PopulatedPark {
        val parameters: Map<String, String?> = mapOf(
            "id" to id
        )
        val url = buildUrl("park", parameters)
        return window.http.get(url).let { bytes ->
            json.decodeFromString(bytes.decodeToString())
        }
    }

    suspend fun searchParksResult(
        query: String? = null,
        regionId: String? = null,
        kommuneId: String? = null,
        cityName: String? = null,
        requireFenced: Boolean = false,
    ) = runCatching {
        searchParks(
            query = query,
            regionId = regionId,
            kommuneId = kommuneId,
            cityName = cityName,
            requireFenced = requireFenced
        )
    }

    suspend fun searchParks(
        query: String? = null,
        regionId: String? = null,
        kommuneId: String? = null,
        cityName: String? = null,
        requireFenced: Boolean = false,
    ): List<PopulatedPark> {
        val parameters: Map<String, String?> = mapOf(
            "query" to query,
            "regionId" to regionId,
            "kommuneId" to kommuneId,
            "cityName" to cityName,
            "requireFencing" to requireFenced.toString()
        )

        val url = buildUrl("parks/search", parameters.filter { it.value?.isNotBlank() == true })
        return window.http.get(url).let { bytes ->
            json.decodeFromString(bytes.decodeToString())
        }
    }

    suspend fun getParkCountByRegionIdResult() = kotlin.runCatching { getParkCountByRegionId() }

    suspend fun getParkCountByRegionId(): Map<String, Int> {
        val url = buildUrl("region/parkCountById")
        return get(url)
    }

    suspend fun getParkCountByKommuneIdResult() = kotlin.runCatching { getParkCountByKommuneId() }

    suspend fun getParkCountByKommuneId(): Map<String, Int> {
        val url = buildUrl("kommune/parkCountById")
        return get(url)
    }

    suspend fun getParkCountByCityNameResult() = kotlin.runCatching { getParkCountByCityName() }

    suspend fun getParkCountByCityName(): Map<String, Int> {
        val url = buildUrl("city/parkCountByName")
        return get(url)
    }

    private fun buildUrl(path: String, parameters: Map<String, String?> = emptyMap()): String {
        val url = baseUrl + path
        val urlWithParameters = url + parameters.entries
            .joinToString(prefix = "?", separator = "&") { (key, value) -> "$key=$value" }
        return urlWithParameters
    }

    private suspend inline fun <reified T> get(apiPath: String): T {
        val storage = runCatching { cache[apiPath]?.let { json.decodeFromString<T>(it) } }
            .getOrNull()

        if (storage != null) {
            return storage
        }

        return window.http.get(apiPath).let { bytes ->
            val string = bytes.decodeToString().also { cache.setItem(apiPath, it) }
            json.decodeFromString(string)
        }
    }
}