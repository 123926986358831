package pixelpark.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.AlwaysUnderlinedLinkVariant
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.base
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Br
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.components.widgets.AppBadges
import pixelpark.localization.strings
import pixelpark.theme.toColorScheme
import pixelpark.utils.thenIfMobile

val FooterStyle = CssStyle.base {
    val onContainer = colorMode.toColorScheme().onSurfaceVariant
    Modifier
        .backgroundColor(colorMode.toColorScheme().surfaceVariant)
        .color(onContainer)
        .setVariable(ColorVar, onContainer)
        .padding(topBottom = 16.px)
}

val PixelMemoryStyle = CssStyle.base {
    val onContainer = colorMode.toColorScheme().onSecondaryContainer
    Modifier
        .backgroundColor(colorMode.toColorScheme().secondaryContainer)
        .color(onContainer)
        .setVariable(ColorVar, onContainer)
        .padding(topBottom = 8.px, leftRight = 16.px)
}

@Composable
private fun FooterSection(
    title: String,
    modifier: Modifier = Modifier,
    content: @Composable ColumnScope.() -> Unit
) {
    Column(
        modifier
            .thenIfMobile { Modifier.fillMaxWidth() }
            .padding(bottom = 32.px, left = 16.px, right = 16.px),
        verticalArrangement = Arrangement.spacedBy(8.px)
    ) {
        H4(Modifier.marginBlock().toAttrs()) {
            SpanText(title)
        }

        content()
    }

}

@Composable
fun Footer(
    strings: PixelParkStrings = strings(),
    modifier: Modifier = Modifier
) {
    Column(modifier) {
        Box(FooterStyle.toModifier().fillMaxWidth(), contentAlignment = Alignment.Center) {
            val linkVariant = AlwaysUnderlinedLinkVariant.then(UncoloredLinkVariant)
            Row(
                modifier = Modifier
                    .flexWrap(FlexWrap.Wrap)
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceAround,
            ) {

                FooterSection(strings.title_website, modifier = Modifier.weight(1f)) {
                    P(Modifier.marginBlock().toAttrs()) {
                        """ 
                        Kirketoften 6A
                        8260, Viby J
                    """
                            .trim()
                            .lines()
                            .forEach {
                                Text(it)
                                Br()
                            }
                    }
                }

                FooterSection(strings.title_links, modifier = Modifier.weight(1f)) {
                    Link(PixelParkRoutes.About.route, strings.title_about_vores_hundeskove, variant = linkVariant)
                    Link(
                        PixelParkRoutes.Definition.route,
                        strings.title_what_is_dog_park,
                        variant = linkVariant
                    )
                    Link(PixelParkRoutes.Rules.route, strings.title_rules_in_dog_park, variant = linkVariant)
                    Link(PixelParkRoutes.Contact.route, strings.title_contact, variant = linkVariant)

                    // Be careful with changing the privacy path
                    Link(PixelParkRoutes.Privacy.route, strings.title_privacy_policy, variant = linkVariant)
                }

                // TODO Reenable app footer section
//                FooterSection(strings.title_apps, modifier = Modifier.weight(1f)) {
//                    Column(verticalArrangement = Arrangement.spacedBy(1.cssRem)) {
//                        AppBadges()
//                    }
//                }
            }
        }

        Box(
            PixelMemoryStyle.toModifier().fillMaxWidth().fontSize(0.8.cssRem).fontWeight(FontWeight.Light),
            contentAlignment = Alignment.Center
        ) {
            SpanText(strings.label_pixel_memory)
        }
    }

}
