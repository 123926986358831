package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.navigation.Link
import org.jetbrains.compose.web.css.px

@Composable
fun AppBadges() {
    val size = 48.px
    // TODO Update link for iOS
    Link("https://play.google.com/store/apps/details?id=com.sebastianrask.pixelpark.android") {
        Image(
            src = "/ic_appstore_badge.png",
            variant = null,
            modifier = Modifier.height(size)
        )
    }

    Link("https://play.google.com/store/apps/details?id=com.sebastianrask.pixelpark.android") {
        Image(
            src = "/ic_google_play_badge.png",
            variant = null,
            modifier = Modifier.height(size)
        )
    }
}