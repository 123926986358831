package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import org.jetbrains.compose.web.css.px
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.with

@Composable
fun Divider(modifier: Modifier = Modifier) {
    val color = PixelParkTheme.colorScheme.onSurface.with(0.25f)
    Box(modifier.height(1.px).fillMaxWidth().backgroundColor(color))
}