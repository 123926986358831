package pixelpark.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.CssStyle
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import pixelpark.PixelParkStrings
import pixelpark.components.layouts.Font.asPrimaryFont
import pixelpark.components.sections.Footer
import pixelpark.components.sections.NavHeader
import pixelpark.localization.strings
import pixelpark.theme.PixelParkTheme
import pixelpark.utils.thenIfMobile
import pixelpark.utils.thenIfNotMobile

object Font {
    const val GoogleSansFlex = "Google Sans Flex"
    const val OpenSans = "OpenSans-Var"
    const val Roboto = "Roboto"

    fun String.asPrimaryFont() = listOf(this, OpenSans, Roboto, "Arial", "Sans-Serif")
        .distinct()
        .toTypedArray()
}


val FontStyle = CssStyle {
    base {
        Modifier.fontFamily(*Font.OpenSans.asPrimaryFont())
            .fontSize(1.05.cssRem)

    }

    cssRule("h1") {
        Modifier
            .fontFamily(*Font.GoogleSansFlex.asPrimaryFont())
            .fontSize(2.4.cssRem)
            .fontWeight(FontWeight.SemiBold)
            .margin(top = 1.5.cssRem, bottom = 2.cssRem)
            .letterSpacing(0.025.em)
            .lineHeight(1.2)
    }

    cssRule("h2") {
        Modifier
            .fontFamily(*Font.GoogleSansFlex.asPrimaryFont())
            .fontWeight(FontWeight.SemiBold)
            .fontSize(1.8.cssRem)
            .margin(top = 1.5.cssRem, bottom = 1.cssRem)
    }

    cssRule("h3") {
        Modifier
            .fontFamily(*Font.GoogleSansFlex.asPrimaryFont())
            .fontSize(1.5.cssRem)
            .margin(topBottom = 1.2.cssRem)
    }

    cssRule("h4") {
        Modifier
            .fontSize(1.2.cssRem)
            .fontWeight(FontWeight.Bolder)
            .margin(top = 1.cssRem, bottom = 0.5.cssRem)
    }

    cssRule("p") {
        Modifier
            .fontSize(1.cssRem)
            .margin(bottom = 0.8.cssRem)
    }

    cssRule("ul") {
        Modifier.fillMaxWidth().overflowWrap(OverflowWrap.BreakWord)
    }
}
val VoresPageContentStyle = CssStyle {
    base {
        Modifier
            .fillMaxSize()
    }
    Breakpoint.MD {
        Modifier
    }
}

@Composable
fun VoresPageLayout(
    subtitle: String? = null,
    header: @Composable ColumnScope.(Modifier) -> Unit = { NavHeader(modifier = it) },
    footer: @Composable ColumnScope.(Modifier) -> Unit = { Footer(modifier = it) },
    content: @Composable ColumnScope.() -> Unit,
) {
    val colorScheme = PixelParkTheme.colorScheme
    val strings: PixelParkStrings = strings()

    LaunchedEffect(subtitle) {
        document.title = listOfNotNull(strings.title_website, subtitle).joinToString(separator = " - ")
    }

    Box(
        Modifier
            .fillMaxWidth()
            .minHeight(100.percent)
            // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
            // space at the bottom). "min-content" means the use the height of the row, which we use for the footer.
            // Since this box is set to *at least* 100%, the footer will always appear at least on the bottom but can be
            // pushed further down if the first row grows beyond the page.
            // Grids are powerful but have a bit of a learning curve. For more info, see:
            // https://css-tricks.com/snippets/css/complete-guide-grid/
//            .gridTemplateRows { size(1.fr); size(minContent) }
            .backgroundColor(colorScheme.background)
            .color(colorScheme.onBackground)
            .setVariable(ColorVar, colorScheme.onBackground)
            .then(FontStyle.toModifier()),
        contentAlignment = Alignment.Center
    ) {

        Column(
            Modifier.fillMaxSize(),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            header(Modifier.fillMaxWidth())

            Column(
                VoresPageContentStyle
                    .toModifier()
                    .thenIfMobile {
                        Modifier.padding(leftRight = 16.px, top = 4.px, bottom = 16.px)
                    }
                    .thenIfNotMobile {
                        Modifier
                            .maxWidth(60.cssRem)
                            .padding(leftRight = 64.px, top = 4.px, bottom = 16.px)
                    },
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }

            footer(Modifier.fillMaxWidth())
        }
    }
}
