package pixelpark

import com.varabyte.kobweb.core.init.InitKobweb
import com.varabyte.kobweb.core.init.InitKobwebContext

@InitKobweb
fun initKobweb(ctx: InitKobwebContext) {
    ctx.router.setErrorHandler { errorCode ->
        ctx.router.navigateTo(PixelParkRoutes.Landing.route)
    }
}