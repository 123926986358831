package pixelpark.theme

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color

class ColorScheme(
    val primary: Color,
    val onPrimary: Color,
    val primaryContainer: Color,
    val onPrimaryContainer: Color,
    val inversePrimary: Color,
    val secondary: Color,
    val onSecondary: Color,
    val secondaryContainer: Color,
    val onSecondaryContainer: Color,
    val tertiary: Color,
    val onTertiary: Color,
    val tertiaryContainer: Color,
    val onTertiaryContainer: Color,
    val background: Color,
    val onBackground: Color,
    val surface: Color,
    val onSurface: Color,
    val surfaceVariant: Color,
    val onSurfaceVariant: Color,
    val surfaceTint: Color,
    val inverseSurface: Color,
    val inverseOnSurface: Color,
    val error: Color,
    val onError: Color,
    val errorContainer: Color,
    val onErrorContainer: Color,
    val outline: Color,
    val outlineVariant: Color,
    val scrim: Color,
    val surfaceBright: Color,
    val surfaceDim: Color,
    val surfaceContainer: Color,
    val surfaceContainerHigh: Color,
    val surfaceContainerHighest: Color,
    val surfaceContainerLow: Color,
    val surfaceContainerLowest: Color,
)

@Composable
fun Modifier.surface(
    backgroundColor: Color,
    contentColor: Color = contentColorFor(backgroundColor),
): Modifier = this
    .backgroundColor(backgroundColor)
    .color(contentColor)

@Composable
@ReadOnlyComposable
fun contentColorFor(color: Color): Color {
    val colorScheme = PixelParkTheme.colorScheme
    return when(color) {
        colorScheme.background -> colorScheme.onBackground
        colorScheme.primary -> colorScheme.onPrimary
        colorScheme.surface,
        colorScheme.surfaceContainer,
        colorScheme.surfaceContainerLow,
        colorScheme.surfaceContainerHigh,
        colorScheme.surfaceDim,
        colorScheme.surfaceContainerHighest -> colorScheme.onSurface
        colorScheme.errorContainer -> colorScheme.onErrorContainer
        colorScheme.primaryContainer -> colorScheme.onPrimaryContainer
        colorScheme.secondaryContainer -> colorScheme.onSecondaryContainer
        colorScheme.tertiaryContainer -> colorScheme.onTertiaryContainer
        colorScheme.inverseSurface -> colorScheme.inverseOnSurface
        else -> colorScheme.onBackground
    }
}

fun Color.with(alpha: Float): Color = this.toRgb().copy(alpha = (255 * alpha).toInt())

fun lightColorScheme() = ColorScheme(
    primary = md_theme_light_primary,
    onPrimary = md_theme_light_onPrimary,
    primaryContainer = md_theme_light_primaryContainer,
    onPrimaryContainer = md_theme_light_onPrimaryContainer,
    secondary = md_theme_light_secondary,
    onSecondary = md_theme_light_onSecondary,
    secondaryContainer = md_theme_light_secondaryContainer,
    onSecondaryContainer = md_theme_light_onSecondaryContainer,
    tertiary = md_theme_light_tertiary,
    onTertiary = md_theme_light_onTertiary,
    tertiaryContainer = md_theme_light_tertiaryContainer,
    onTertiaryContainer = md_theme_light_onTertiaryContainer,
    error = md_theme_light_error,
    errorContainer = md_theme_light_errorContainer,
    onError = md_theme_light_onError,
    onErrorContainer = md_theme_light_onErrorContainer,
    background = md_theme_light_background,
    onBackground = md_theme_light_onBackground,
    surface = md_theme_light_surface,
    onSurface = md_theme_light_onSurface,
    surfaceVariant = md_theme_light_surfaceVariant,
    onSurfaceVariant = md_theme_light_onSurfaceVariant,
    outline = md_theme_light_outline,
    inverseOnSurface = md_theme_light_inverseOnSurface,
    inverseSurface = md_theme_light_inverseSurface,
    inversePrimary = md_theme_light_inversePrimary,
    surfaceTint = md_theme_light_surfaceTint,
    outlineVariant = md_theme_light_outlineVariant,
    scrim = md_theme_light_scrim,
    surfaceBright = Color.rgb(0x000000),
    surfaceDim = Color.rgb(0x000000),
    surfaceContainer = Color.rgb(0x000000),
    surfaceContainerLow = Color.rgb(0x000000),
    surfaceContainerHigh = Color.rgb(0x000000),
    surfaceContainerLowest = Color.rgb(0x000000),
    surfaceContainerHighest = Color.rgb(0x000000)
)
