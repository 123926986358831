package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.minWidth
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.px
import pixelpark.utils.isMobile

@Composable
fun NavigateForwardIndicator() {
    val breakpoint = rememberBreakpoint()
    val size = when {
        breakpoint.isMobile() -> 24.px
        else -> 36.px
    }
    PixelParkIcon(
        Icon.ChevronForward,
        modifier = Modifier.size(size).minWidth(size).opacity(0.7)
    )
}