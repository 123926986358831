package pixelpark.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun RulesPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("Rules.md", mapOf())) {
        pixelpark.components.layouts.MarkdownLayout("Regler") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("regler-for-hundeskove-i-danmark") }) {
                org.jetbrains.compose.web.dom.Text("Regler for Hundeskove i Danmark")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Når du besøger en ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("hundeskov")
                }
                org.jetbrains.compose.web.dom.Text(" i Danmark, er det vigtigt at følge visse regler for at sikre, at alle får en god oplevelse i naturen. ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Naturstyrelsens")
                }
                org.jetbrains.compose.web.dom.Text(" regler for deres hundeskove gælder ikke nødvendigvis for hundeskove, der drives af kommuner eller private ejere. Derfor bør du altid være opmærksom på de specifikke regler, som ofte er opsat ved indgangene til den pågældende hundeskov.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("grundlæggende-regler-i-hundeskove") }) {
                org.jetbrains.compose.web.dom.Text("Grundlæggende Regler i Hundeskove")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Som hundeejer har du ansvaret for at have fuld kontrol over din hund. Det betyder, at du skal kunne kalde din hund til dig, hvis den møder andre hunde eller mennesker. Det er også din pligt at sikre, at din hund ikke udviser aggressiv adfærd over for andre.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hundeskove er fantastiske steder til at socialisere din hund, men det skal altid ske under kontrollerede forhold. Mange hundeskove har også særlige områder, såsom hvalpegårde eller kravlegårde, der er designet til mindre hunderacer eller hvalpe, så de kan lege trygt.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("forskellige-regler-i-hundeskove") }) {
                org.jetbrains.compose.web.dom.Text("Forskellige Regler i Hundeskove")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Reglerne kan variere mellem forskellige hundeskove i Danmark. Mens Naturstyrelsen har sine retningslinjer for statsejede hundeskove, kan kommunale og private hundeskove have deres egne sæt regler. Disse regler er ofte opsat ved indgangene til hundeskoven, så det er vigtigt at læse dem grundigt, inden du slipper din hund løs.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Ved at følge de opstillede regler kan vi alle bidrage til, at hundeskovene forbliver sikre og behagelige steder for både hunde og ejere. Gør din tur i skoven til en positiv oplevelse for alle ved at respektere naturen, de andre besøgende og deres hunde.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hos ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Vores Hundeskove")
                }
                org.jetbrains.compose.web.dom.Text(" ønsker vi at give dig den bedst mulige oplevelse i naturen, og det starter med at kende og overholde reglerne i de forskellige hundeskove rundt omkring i Danmark.")
            }
        }
    }
}
