package pixelpark.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.TextInput
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.components.layouts.FontStyle
import pixelpark.localization.strings
import pixelpark.theme.PixelParkTheme
import pixelpark.utils.isMobile
import pixelpark.utils.thenIfMobile
import pixelpark.utils.thenIfNotMobile

val SearchInputButton = Keyframes {
    from { Modifier.opacity(0).translateX(100.percent) }
    to { Modifier.opacity(1).translateX(0.percent) }
}

@Composable
fun SearchInput(
    initialQuery: String? = null,
    compact: Boolean = false,
    strings: PixelParkStrings = strings()
) {
    val coroutineScope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    val colorScheme = PixelParkTheme.colorScheme

    val placeholder = if (breakpoint.isMobile() || compact)
        strings.hint_search_short
    else
        strings.hint_search_long
    val height = if (compact) 48.px else 64.px
    val borderRadius = height * 0.37

    var input by remember { mutableStateOf(initialQuery ?: "") }

    val navigate: () -> Unit = unit@{
        if (input.isBlank()) return@unit
        val route = PixelParkRoutes.SearchResult(input).route
        coroutineScope.launch { ctx.router.navigateTo(route) }
    }
    var buttonVisible by remember { mutableStateOf(false) }

    // Define styles with animations
    val buttonStyle by remember {
        val duration = 350.ms
        val timing = AnimationTimingFunction.EaseInOut
        derivedStateOf {
            Modifier
                .opacity(if (buttonVisible) 1f else 0f) // Fade in and out
                .transition(
                    Transition.of("opacity", duration, timing, null),
                )
        }
    }

    val minWidth = if (compact) 25.cssRem else 30.cssRem

    LaunchedEffect(input) {
        buttonVisible = input.isNotBlank()
    }

    Box(
        contentAlignment = Alignment.CenterEnd,
        modifier = Modifier
            .thenIfMobile { Modifier.fillMaxWidth() }
            .thenIfNotMobile { Modifier.widthIn(min = minWidth) }
    ) {
        TextInput(
            value = input,
            attrs = Modifier
                .padding(topBottom = 8.px, leftRight = 16.px)
                .then(FontStyle.toModifier())
                .height(height)
                .fillMaxWidth()
                .outline(style = LineStyle.None)
                .border { style(LineStyle.None) }
                .borderRadius(borderRadius)
                .thenIf(compact) {
                    Modifier.outline(style = LineStyle.Solid)
                }
                .backgroundColor(Color.white)
                .fontSize(20.px)
                .onKeyDown {
                    if (it.key.lowercase() != "enter") return@onKeyDown
                    navigate()
                }
                .toAttrs {
                    placeholder(placeholder)
                    onInput { input = it.value }
                }
        )

        TextButton(
            strings.action_search,
            shape = RectF(borderRadius),
            backgroundColor = colorScheme.primaryContainer,
            modifier = buttonStyle
                .fontWeight(FontWeight.Bold)
                .height(height)
                .onClick { navigate() }
        )
    }
}