package pixelpark.theme

import com.varabyte.kobweb.compose.css.FontWeight

class Typography(
    val displayLarge: TextStyle = TextStyle(
        size = 57,
        lineHeight = 64,
        fontWeight = FontWeight.Normal
    ),
    val displayMedium: TextStyle = TextStyle(
        size = 45,
        lineHeight = 52,
        fontWeight = FontWeight.Normal
    ),
    val displaySmall: TextStyle = TextStyle(
        size = 36,
        lineHeight = 44,
        fontWeight = FontWeight.Normal
    ),
    val headlineLarge: TextStyle = TextStyle(
        size = 32,
        lineHeight = 40,
        fontWeight = FontWeight.Normal
    ),
    val headlineMedium: TextStyle = TextStyle(
        size = 28,
        lineHeight = 36,
        fontWeight = FontWeight.Normal
    ),
    val headlineSmall: TextStyle = TextStyle(
        size = 24,
        lineHeight = 32,
        fontWeight = FontWeight.Normal
    ),
    val titleLarge: TextStyle = TextStyle(
        size = 22,
        lineHeight = 28,
        fontWeight = FontWeight.Normal
    ),
    val titleMedium: TextStyle = TextStyle(
        size = 16,
        lineHeight = 24,
        fontWeight = FontWeight.Medium
    ),
    val titleSmall: TextStyle = TextStyle(
        size = 14,
        lineHeight = 20,
        fontWeight = FontWeight.Medium
    ),
    val bodyLarge: TextStyle = TextStyle(
        size = 16,
        lineHeight = 24,
        fontWeight = FontWeight.Normal
    ),
    val bodyMedium: TextStyle = TextStyle(
        size = 14,
        lineHeight = 20,
        fontWeight = FontWeight.Normal
    ),
    val bodySmall: TextStyle = TextStyle(
        size = 12,
        lineHeight = 16,
        fontWeight = FontWeight.Normal
    ),
    val labelLarge: TextStyle = TextStyle(
        size = 14,
        lineHeight = 20,
        fontWeight = FontWeight.Medium
    ),
    val labelMedium: TextStyle = TextStyle(
        size = 12,
        lineHeight = 16,
        fontWeight = FontWeight.Medium
    ),
    val labelSmall: TextStyle = TextStyle(
        size = 11,
        lineHeight = 16,
        fontWeight = FontWeight.Medium
    ),
)