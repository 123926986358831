package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import org.jetbrains.compose.web.css.px
import pixelpark.PixelParkStrings
import pixelpark.localization.strings
import pixelpark.model.ParkFencing
import pixelpark.model.PopulatedPark
import pixelpark.util.format

@Composable
fun ParkStatusRow(park: PopulatedPark, showFavouriteStatus: Boolean, size: StatusSize, modifier: Modifier = Modifier) {
    Row(
        modifier = modifier.fillMaxWidth(),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.spacedBy(8.px)
    ) {
        RatingStatus(stars = park.rating, ratingCount = park.point?.ratingsCount ?: 0, size = size)
        FencingStatus(fencing = park.features.fencing, size = size)

        park.distanceToUserKm?.let { distance ->
            DistanceStatus(distanceKm = distance, size = size)
        }

        if (park.favourite && showFavouriteStatus) {
            FavouriteStatus(size = size)
        }
    }
}

@Composable
fun FencingStatus(fencing: ParkFencing, size: StatusSize, strings: PixelParkStrings = strings()) {
    val emphasis = when (fencing) {
        ParkFencing.Fenced -> StatusEmphasis.High
        ParkFencing.PartiallyFenced, ParkFencing.Unfenced -> StatusEmphasis.Medium
    }
    val icon = when (fencing) {
        ParkFencing.Fenced, ParkFencing.PartiallyFenced -> Icon.Fence
        ParkFencing.Unfenced -> Icon.FenceOff
    }

    val text = when (fencing) {
        ParkFencing.Fenced, ParkFencing.PartiallyFenced -> strings.label_fenced
        ParkFencing.Unfenced -> strings.label_unfenced
    }
    Status(
        icon = { PixelParkIcon(icon, Modifier.fillMaxSize()) },
        text = text,
        emphasis = emphasis,
        size = size
    )
}

@Composable
fun DistanceStatus(distanceKm: Double, size: StatusSize) {
    val formattedDistance = distanceKm.format(1)
    Status(
        icon = { PixelParkIcon(Icon.Distance, Modifier.fillMaxSize()) },
        text = "$formattedDistance km",
        emphasis = StatusEmphasis.Medium,
        size = size
    )
}

@Composable
fun FavouriteStatus(size: StatusSize) {
    Status(
        icon = { PixelParkIcon(Icon.FavouriteFilled, Modifier.fillMaxSize()) },
        text = null,
        emphasis = StatusEmphasis.Custom(Color.rgb(255, 0, 0), Color.rgb(0, 0, 0)),
        size = size
    )
}

@Composable
fun RatingStatus(stars: Double, ratingCount: Int?, size: StatusSize) {
    val emphasis = when {
        stars > 4.0 -> StatusEmphasis.High
        stars > 3.0 -> StatusEmphasis.Medium
        else -> StatusEmphasis.Low
    }

    Status(
        icon = { PixelParkIcon(Icon.Star, Modifier.fillMaxSize()) },
        text = stars.format(1),
        emphasis = emphasis,
        size = size,
        secondaryText = ratingCount?.let { "($it)" }
    )
}

@Composable
fun ReviewStatus(
    stars: Double,
    size: StatusSize,
    modifier: Modifier = Modifier
) {
    Status(
        icon = { PixelParkIcon(Icon.Star, Modifier.fillMaxSize()) },
        text = stars.format(1),
        size = size,
        emphasis = StatusEmphasis.Medium,
        modifier = modifier
    )
}