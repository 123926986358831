package pixelpark.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.marginBlock
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pixelpark.PixelParkRoutes
import pixelpark.Resource
import pixelpark.components.sections.NavHeader
import pixelpark.components.widgets.*
import pixelpark.model.PopulatedPark
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.textStyle

@Composable
fun ParkListLayout(
    title: String,
    description: String,
    errorTitle: String? = null,
    emptyContent: @Composable () -> Unit = {},
    header: @Composable ColumnScope.(Modifier) -> Unit = { NavHeader(modifier = it) },
    parks: Resource<List<PopulatedPark>>
) {
    VoresPageLayout(subtitle = title, header = header) {
        Box(
            contentAlignment = Alignment.TopCenter,
            modifier = Modifier.fillMaxHeight()
                .fillMaxWidth()
        ) {
            Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {
                H1 {
                    Text(title)
                }

                P {
                    Text(description)
                }

                ResourceView(
                    parks,
                    errorTitle = errorTitle,
                    allowNull = true,
                    modifier = Modifier.fillMaxWidth()
                ) {
                    ParkList(it ?: emptyList(), modifier = Modifier.fillMaxWidth())

                    if (it.isNullOrEmpty()) {
                        emptyContent()
                    }
                }
            }
        }
    }
}

@Composable
fun ParkList(parks: List<PopulatedPark>, modifier: Modifier = Modifier) {
    Column(verticalArrangement = Arrangement.spacedBy(8.px), modifier = modifier) {
        parks.forEachIndexed { index, park ->
            Link(
                path = PixelParkRoutes.ParkDetails(park).route,
                variant = UncoloredLinkVariant.then(UndecoratedLinkVariant),
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                ParkListItem(
                    park = park,
                    modifier = Modifier
                        .padding(bottom = 8.px)
                )
            }

            if (index != parks.lastIndex) {
                Divider()
            }
        }
    }
}

@Composable
fun ParkListItem(park: PopulatedPark, modifier: Modifier = Modifier) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier.fillMaxWidth()
    ) {
        Column(
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.spacedBy(0.px),
            modifier = Modifier.weight(1f)
        ) {
            val type = PixelParkTheme.typography
            H3(Modifier.marginBlock().toAttrs()) {
                SpanText(
                    park.title.base,
                    Modifier.textStyle(type.titleMedium.copy(lineHeight = null))
                )
            }

            SpanText(park.displayAddress, Modifier.textStyle(type.bodyMedium))

            ParkStatusRow(
                park = park,
                showFavouriteStatus = false,
                size = StatusSize.Large,
                modifier = Modifier.padding(top = 8.px)
            )
        }

        NavigateForwardIndicator()
    }
}
