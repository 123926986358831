package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.dom.B
import org.jetbrains.compose.web.dom.Text
import pixelpark.FormattedString
import pixelpark.Formatting

@Composable
fun FormattedText(item: List<FormattedString>) {
    item.forEach { formattedString ->
        val text: @Composable () -> Unit = {
            Text(formattedString.value)
        }

        when (formattedString.formating) {
            Formatting.Bold -> B { text() }
            Formatting.Normal -> text()
        }
    }
}