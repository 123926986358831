package pixelpark.pages.region

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.widgets.CountTextButton
import pixelpark.localization.strings
import pixelpark.model.Region
import pixelpark.utils.isMobile
import pixelpark.utils.thenIfMobile

@Page("/regioner")
@Composable
fun RegionsPage() {
    VoresPageLayout(subtitle = null) {
        RegionsCallOut()
    }
}

@Composable
fun RegionsCallOut(
    compact: Boolean = false,
    strings: PixelParkStrings = strings(),
    modifier: Modifier = Modifier
) {
    var regions by remember { mutableStateOf<List<Region>>(emptyList()) }
    var parkCountByRegion by remember { mutableStateOf<Map<String, Int>>(emptyMap()) }

    LaunchedEffect(Unit) {
        launch {
            regions = RegionApi.getRegions()
        }
        launch {
            parkCountByRegion = ParksApi.getParkCountByRegionIdResult().getOrDefault(emptyMap())
        }
    }

    Box(
        contentAlignment = Alignment.TopCenter,
        modifier = modifier
            .fillMaxHeight()
            .fillMaxWidth()
    ) {
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {
            if (compact) {
                H2 {
                    Text(strings.title_regions_callout)
                }
            } else {
                H1 {
                    Text(strings.title_regions_callout)
                }
            }


            strings.regions_callout_lines(regions.toList().size).forEach {
                P { SpanText(it) }
            }

            Box(Modifier.size(1.cssRem))

            Column(verticalArrangement = Arrangement.spacedBy(8.px), modifier = Modifier.fillMaxWidth()) {
                regions.forEachIndexed { index, region ->
                    Link(
                        path = PixelParkRoutes.RegionParks(region).route,
                        variant = UncoloredLinkVariant.then(UndecoratedLinkVariant),
                        modifier = Modifier.thenIfMobile { Modifier.fillMaxWidth() }
                    ) {
                        val breakpoint = rememberBreakpoint()

                        CountTextButton(
                            count = parkCountByRegion[region.id],
                            text = strings.display_name(
                                region = region,
                                excludeRegion = compact,
                                compact = breakpoint.isMobile()
                            ),
                            delayFactor = index
                        )
                    }
                }
            }
        }
    }
}
