package pixelpark.utils

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import pixelpark.components.widgets.Icon
import pixelpark.localization.strings
import pixelpark.model.ParkFacility
import pixelpark.model.ParkFacility.*

val ParkFacility.displayName: String
    @Composable
    get() {
        val strings = strings()
        return when (this) {
            SmallDogsArea -> strings.label_feature_small_dogs_area
            Toilet -> strings.label_feature_toilet
            Water -> strings.label_feature_water
            TrashCan -> strings.label_feature_trash
            Agility -> strings.label_feature_agility
            Tables -> strings.label_feature_tables
            Wetland -> strings.label_feature_wet_area
            Accessible -> strings.label_feature_accessible
        }
    }
val ParkFacility.icon: Icon
    get() = when (this) {
        SmallDogsArea -> Icon.Fence
        Toilet -> Icon.Toilet
        Water -> Icon.Water
        TrashCan -> Icon.Trash
        Agility -> Icon.Agility
        Tables -> Icon.Table
        Wetland -> Icon.Wetland
        Accessible -> Icon.Accessible
    }