package pixelpark.localization

import org.w3c.dom.Window

enum class SupportedLanguage {
    Danish, German, English, Swedish, Norwegian;

    val iso: String get() = when(this) {
        Danish -> "da"
        German -> "de"
        English -> "en"
        Swedish -> "sv"
        Norwegian -> "no"
    }
}

val Window.language: SupportedLanguage
    get() = navigator.languages
    .mapNotNull { it.split("-").firstOrNull() }
    .distinct()
    .firstNotNullOfOrNull { iso ->
        SupportedLanguage.entries.firstOrNull { it.iso == iso }
    }
    ?: SupportedLanguage.English