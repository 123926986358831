package pixelpark.pages.kommune

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.widgets.CountTextButton
import pixelpark.localization.strings
import pixelpark.model.Kommune
import pixelpark.utils.isMobile
import pixelpark.utils.thenIfMobile

@Page("/kommuner")
@Composable
fun KommunesPage() {
    VoresPageLayout(subtitle = null) {
        KommunesCallOut()
    }
}

@Composable
fun KommunesCallOut(
    strings: PixelParkStrings = strings(),
    modifier: Modifier = Modifier
) {
    var kommunes by remember { mutableStateOf<List<Kommune>>(emptyList()) }
    var parkCountByKommuneId by remember { mutableStateOf<Map<String, Int>>(emptyMap()) }

    LaunchedEffect(Unit) {
        launch {
            kommunes = RegionApi.getKommunes()
        }
        launch {
            parkCountByKommuneId = ParksApi.getParkCountByKommuneIdResult().getOrDefault(emptyMap())
        }
    }

    val listTransitionModifier = rememberRegionEntryListTransitionModifier(kommunes)

    Box(
        contentAlignment = Alignment.TopCenter,
        modifier = modifier
            .fillMaxHeight()
            .fillMaxWidth()
    ) {
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {
            H1 {
                Text(strings.title_kommunes_callout)
            }

            strings.kommunes_callout_lines(kommunes.size).forEach {
                P { Text(it) }
            }

            Box(Modifier.size(1.cssRem))

            Column(listTransitionModifier, verticalArrangement = Arrangement.spacedBy(8.px)) {
                kommunes.forEachIndexed { index, kommune ->
                    Link(
                        path = PixelParkRoutes.KommuneParks(kommune).route,
                        modifier = Modifier.thenIfMobile { Modifier.fillMaxWidth() }
                    ) {
                        val breakpoint = rememberBreakpoint()
                        val count = parkCountByKommuneId[kommune.id]
                        CountTextButton(
                            count = count,
                            text = strings.display_name(
                                kommune = kommune,
                                excludeKommune = true,
                                compact = breakpoint.isMobile()
                            ),
                            delayFactor = index
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun rememberRegionEntryListTransitionModifier(entryList: List<Any>): Modifier {
    val ctx = rememberPageContext()
    if (ctx.isExporting) return Modifier
    return remember(entryList) {
        val duration = 500.ms
        val timing = AnimationTimingFunction.EaseInOut
        return@remember Modifier
            .minHeight(500.px)
            .opacity(if (entryList.isNotEmpty()) 1f else 0f) // Fade in and out
            .translateY(if (entryList.isNotEmpty()) 0.percent else 75.px)
            .transition(
                Transition.of("opacity", duration, timing, null),
                Transition.of("translate", duration, timing, null)
            )
    }
}
