package pixelpark.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.*
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.widgets.*
import pixelpark.localization.strings
import pixelpark.pages.region.RegionsCallOut
import pixelpark.utils.thenIfMobile

@Composable
fun LandingPage() {
    VoresPageLayout(
        header = { LandingPageHeader(it) },
        content = { LandingPageContent() }
    )
}

@Composable
private fun LandingPageHeader(
    modifier: Modifier,
    strings: PixelParkStrings = strings()
) {
    ExpandedPageHeader(
        minHeight = 20.cssRem,
        content = {
            Box(
                modifier = Modifier
                    .padding(leftRight = 16.px)
                    .fillMaxWidth()
                    .fillMaxHeight(),
                contentAlignment = Alignment.TopCenter
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .padding(bottom = 3.cssRem),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    H1 {
                        Text(strings.title_website)
                    }

                    SearchInput(compact = false)
                }
            }
        },
        modifier = modifier
    )
}

@Composable
private fun LandingPageContent() {
    Column(
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.spacedBy(2.cssRem),
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxWidth()
            .padding(bottom = 2.cssRem)

    ) {
        CallOutGrouping()
        RegionsCallOut(compact = true)
        // TODO Reenable app download callout
//        CallOutAppDownload()
    }
}

@Composable
private fun CallOutGrouping() {
    val strings = strings()

    Column {
        H2 {
            Text(strings.title_grouping_callout)
        }

        strings.lines_grouping_callout.forEach {
            Text(it)
        }

        H4 {
            Text(strings.label_grouping_callout)
        }

        ExploreParksByGrouping(modifier = Modifier.fillMaxWidth())
    }
}

@Composable
fun ExploreParksByGrouping(
    strings: PixelParkStrings = strings(),
    modifier: Modifier = Modifier
) {
    Row(
        modifier = modifier.flexWrap(FlexWrap.Wrap),
        horizontalArrangement = Arrangement.spacedBy(16.px),
    ) {
        listOf(
            PixelParkRoutes.Cities.route to strings.action_city_option,
            PixelParkRoutes.Regions.route to strings.action_region_option,
            PixelParkRoutes.Kommunes.route to strings.action_kommune_option
        ).forEach { (path, title) ->
            Link(path, Modifier.thenIfMobile { Modifier.fillMaxWidth() }) {
                TextButton(title, Modifier.margin(bottom = 8.px))
            }
        }
    }
}

@Composable
private fun CallOutAppDownload(strings: PixelParkStrings = strings()) {

    Row(
        modifier = Modifier
            .flexWrap(FlexWrap.Wrap)
            .fillMaxWidth(),
    ) {
        Column(Modifier) {
            H3 {
                Text(strings.title_apps_callout)
            }

            strings.lines_apps_callout.forEach {
                Text(it)
            }

            Ul {
                strings.list_items_apps_callout.forEach { item ->
                    Li {
                        FormattedText(item)
                    }
                }
            }

            Row(horizontalArrangement = Arrangement.spacedBy(1.cssRem)) {
                AppBadges()
            }
        }
    }
}

