package pixelpark.theme

import com.varabyte.kobweb.silk.theme.colors.ColorMode

object SitePalettes {
    val light = lightColorScheme()
}

fun ColorMode.toColorScheme(): ColorScheme {
    // For now, we only support light palette
    return when (this) {
        ColorMode.LIGHT -> SitePalettes.light
        ColorMode.DARK -> SitePalettes.light
    }
}
