package pixelpark.api

import com.varabyte.kobweb.browser.api
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import pixelpark.model.City
import pixelpark.model.Kommune
import pixelpark.model.Region

object RegionApi {
    private val json = Json(from = Json.Default) { ignoreUnknownKeys = true }

    suspend fun getRegions(): List<Region> {
        return window.api.get("region/get/all").let { bytes ->
            json.decodeFromString<List<Region>>(bytes.decodeToString())
        }
    }

    suspend fun getRegion(displayId: String): Region {
        return window.api.get("region/get?displayId=$displayId").let { bytes ->
            json.decodeFromString(bytes.decodeToString())
        }
    }

    suspend fun getKommunes(): List<Kommune> {
        return window.api.get("kommune/get/all").let { bytes ->
            json.decodeFromString<List<Kommune>>(bytes.decodeToString())
        }
    }

    suspend fun getKommune(displayId: String): Kommune {
        return window.api.get("kommune/get?displayId=$displayId").let { bytes ->
            json.decodeFromString(bytes.decodeToString())
        }
    }

    suspend fun getCities(): List<City> = withContext(Dispatchers.Main) {
        window.api.get("city/get/all").let { bytes ->
            json.decodeFromString<List<City>>(bytes.decodeToString())
        }
    }

    suspend fun getCity(displayId: String): City {
        return window.api.get("city/get?displayId=$displayId").let { bytes ->
            json.decodeFromString(bytes.decodeToString())
        }
    }
}