package pixelpark

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import kotlinx.browser.window
import okio.ByteString.Companion.decodeBase64
import okio.ByteString.Companion.encodeUtf8
import pixelpark.localization.language
import pixelpark.model.City
import pixelpark.model.Kommune
import pixelpark.model.PopulatedPark
import pixelpark.model.Region

sealed class PixelParkRoutes(private val _route: String) {
    val route: String
        get() {
            val isoPath = window.language.iso
            // TODO Prefix route with iso path to support locales
            return _route
        }

    data object Landing : PixelParkRoutes("/")
    data object DataRequest : PixelParkRoutes("/data-request")
    data object About : PixelParkRoutes("/about")
    data object Definition : PixelParkRoutes("/definition")
    data object Rules : PixelParkRoutes("/rules")
    data object Contact : PixelParkRoutes("/contact")
    data object Privacy : PixelParkRoutes("/privacy")

    data object Cities : PixelParkRoutes("/byer")
    data class CityParks(val city: City) : PixelParkRoutes("/byer/${city.displayId}/hundeskove")

    data object Regions : PixelParkRoutes("/regioner")
    data class RegionParks(val region: Region) : PixelParkRoutes("/regioner/${region.displayId}/hundeskove")

    data object Kommunes : PixelParkRoutes("/kommuner")
    data class KommuneParks(val kommune: Kommune) : PixelParkRoutes("/kommuner/${kommune.displayId}/hundeskove")

    data class SearchResult(
        val query: String
    ) : PixelParkRoutes(
        "/hundeskove/soeg" +
                "?query=${query.encodeUtf8().base64Url()}" +
                "&requireFencing=false"
    ) {
        data class SearchResultParams(
            val query: String?,
            val requireFenced: Boolean
        ) {
            constructor(params: Map<String, String>) : this(
                query = params["query"]?.decodeBase64()?.utf8(),
                requireFenced = params["requireFencing"]?.toBoolean() ?: false,
            )
        }
    }

    data class ParkDetails(
        val park: PopulatedPark
    ) : PixelParkRoutes(
        "/hundeskove/detaljer" +
                "?id=${park.id}" +
                "&title=${park.title.base.encodeUtf8().base64Url()}"
    ) {
        data class ParkDetailParams(
            val id: String,
            val title: String?,
        ) {
            constructor(params: Map<String, String>) : this(
                id = params["id"] ?: "",
                title = params["title"]?.decodeBase64()?.utf8(),
            )
        }
    }
}

