package pixelpark.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.marginBlock
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Text
import pixelpark.*
import pixelpark.api.ParksApi
import pixelpark.components.layouts.ParkListLayout
import pixelpark.components.rememberResourceState
import pixelpark.components.sections.NavHeader
import pixelpark.components.widgets.*
import pixelpark.localization.strings
import pixelpark.model.PopulatedPark

@Page("/hundeskove/soeg")
@Composable
fun SearchParksPage(
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()
    val params = PixelParkRoutes.SearchResult.SearchResultParams(ctx.route.queryParams)
    var parks by rememberResourceState<List<PopulatedPark>>()

    LaunchedEffect(params) {
        if (ctx.isExporting) return@LaunchedEffect
        if (params.query.isNullOrBlank()) {
            parks = Resource.error(IllegalStateException("Invalid search term"))
            return@LaunchedEffect
        }

        parks = ParksApi.searchParksResult(query = params.query, requireFenced = params.requireFenced)
            .asResource()
    }

    ParkListLayout(
        title = strings.search_result(params.query ?: ""),
        description = "",
        parks = parks,
        emptyContent = {
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.spacedBy(8.px)
            ) {

                H3(Modifier.textAlign(TextAlign.Center).marginBlock().toAttrs()) {
                    Text(strings.empty_search_result_title(query = params.query ?: ""))
                }

                strings.empty_search_result_lines(params.query ?: "").forEach {
                    SpanText(
                        text = it,
                        modifier = Modifier.textAlign(TextAlign.Center)
                    )
                }

                ExploreParksByGrouping()
            }
        },
        header = {
            SearchPageHeader(initialQuery = params.query, modifier = it)
        }
    )
}


@Composable
private fun SearchPageHeader(
    initialQuery: String?,
    modifier: Modifier,
) {
    NavHeader(
        iconOnly = true,
        inlineContent = {
            SearchInput(initialQuery, compact = true)
        },
        modifier = modifier
    )
}