package pixelpark.pages.kommune

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.coroutines.launch
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.asResource
import pixelpark.components.layouts.ParkListLayout
import pixelpark.model.Kommune
import pixelpark.model.PopulatedPark
import pixelpark.components.rememberResourceState
import pixelpark.localization.strings

@Page("/kommuner/{kommuneId}/hundeskove")
@Composable
fun KommuneParksPage(
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()
    val kommuneId = ctx.route.params.getValue("kommuneId")
    var kommune by remember { mutableStateOf<Kommune?>(null) }
    var parks by rememberResourceState<List<PopulatedPark>>()

    LaunchedEffect(kommuneId) {
        kommune = RegionApi.getKommune(kommuneId)
    }

    LaunchedEffect(kommune) {
        if (ctx.isExporting) return@LaunchedEffect
        parks = ParksApi.searchParksResult(kommuneId = kommune?.id ?: return@LaunchedEffect)
            .asResource()
    }

    ParkListLayout(
        title = kommune?.let {
            strings.display_name(kommune = it, excludeKommune = false)
        } ?: "",
        description = "",
        parks = parks
    )
}
