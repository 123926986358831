package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.components.navigation.UncoloredLinkVariant
import com.varabyte.kobweb.silk.components.navigation.UndecoratedLinkVariant
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.addVariant
import com.varabyte.kobweb.silk.style.toModifier
import org.jetbrains.compose.web.css.px
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.contentColorFor


var TextButtonLinkVariant = LinkStyle.addVariant {
    UndecoratedLinkVariant.then(UncoloredLinkVariant)
}

@Composable
fun TextButton(
    text: String,
    modifier: Modifier = Modifier,
    shape: Shape = RectF(12.px),
    backgroundColor: Color = PixelParkTheme.colorScheme.primary,
    contentColor: Color = contentColorFor(backgroundColor)
) {
    Surface(
        modifier = LinkStyle.toModifier(TextButtonLinkVariant)
            .then(modifier)
            .backgroundColor(backgroundColor)
            .color(contentColor)
            .fontWeight(FontWeight.Medium)
            .padding(leftRight = 24.px, topBottom = 8.px)
            .heightIn(min = 48.px)
            .pixelParkClip(shape),
        contentAlignment = Alignment.Center
    ) {
        Row {
            SpanText(text)
        }
    }
}