import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.core.`init`.InitKobwebContext
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { pixelpark.pages.Index() }
        ctx.router.register("/about") { pixelpark.pages.AboutPage() }
        ctx.router.register("/byer") { pixelpark.pages.city.CitiesPage() }
        ctx.router.register("/byer/{displayId}/hundeskove") { pixelpark.pages.city.CityParksPage() }
        ctx.router.register("/contact") { pixelpark.pages.ContactPage() }
        ctx.router.register("/data-request") { pixelpark.pages.DataRequestPage() }
        ctx.router.register("/definition") { pixelpark.pages.DefinitionPage() }
        ctx.router.register("/hundeskove/detaljer") { pixelpark.pages.ParkDetailsPage() }
        ctx.router.register("/hundeskove/soeg") { pixelpark.pages.SearchParksPage() }
        ctx.router.register("/kommuner") { pixelpark.pages.kommune.KommunesPage() }
        ctx.router.register("/kommuner/{kommuneId}/hundeskove") {
                pixelpark.pages.kommune.KommuneParksPage() }
        ctx.router.register("/privacy") { pixelpark.pages.PrivacyPage() }
        ctx.router.register("/regioner") { pixelpark.pages.region.RegionsPage() }
        ctx.router.register("/regioner/{regionId}/hundeskove") {
                pixelpark.pages.region.RegionParksPage() }
        ctx.router.register("/rules") { pixelpark.pages.RulesPage() }

        pixelpark.initKobweb(ctx)
    }
    router.setLegacyRouteRedirectStrategy(Router.LegacyRouteRedirectStrategy.ALLOW)
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerStyle("headline-text", pixelpark.HeadlineTextStyle)
        ctx.theme.registerStyle("subheadline-text", pixelpark.SubheadlineTextStyle)
        ctx.theme.registerStyle("font", pixelpark.components.layouts.FontStyle)
        ctx.theme.registerStyle("vores-page-content",
                pixelpark.components.layouts.VoresPageContentStyle)
        ctx.theme.registerStyle("footer", pixelpark.components.sections.FooterStyle)
        ctx.theme.registerStyle("pixel-memory", pixelpark.components.sections.PixelMemoryStyle)
        ctx.theme.registerStyle("nav-header", pixelpark.components.sections.NavHeaderStyle)
        ctx.theme.registerVariant("-circle", pixelpark.CircleButtonVariant
        )
        ctx.theme.registerVariant("-uncolored", pixelpark.UncoloredButtonVariant
        )
        ctx.theme.registerVariant("-text-button", pixelpark.components.widgets.TextButtonLinkVariant
        )
        ctx.theme.registerKeyframes("side-menu-slide-in",
                pixelpark.components.sections.SideMenuSlideInAnim)
        ctx.theme.registerKeyframes("count-text", pixelpark.components.widgets.CountTextKeyframes)
        ctx.theme.registerKeyframes("paw-loader-key-frames",
                pixelpark.components.widgets.PawLoaderKeyFrames)
        ctx.theme.registerKeyframes("search-input-button",
                pixelpark.components.widgets.SearchInputButton)
        pixelpark.initColorMode(ctx)
        pixelpark.initSiteStyles(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Vores Hundeskove", "ENV" to "PROD"))
    renderComposable(rootElementId = "root") {
        pixelpark.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
