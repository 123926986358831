package pixelpark

import com.varabyte.kobweb.core.AppGlobals

enum class Environment(val value: String) {
    Development("DEV"), QualityAssurance("QA"), Production("PROD");

    val baseUrl: String
        get() = when (this) {
            Development -> "http://localhost:10000/"
            QualityAssurance -> "https://pixelpark-spring-qa.onrender.com/"
            Production -> "https://www.api.voreshundeskove.dk/"
        }
}

val AppGlobals.environment: Environment
    get() {
        val raw = getValue("ENV")
        return Environment.entries.first { raw == it.value }
    }