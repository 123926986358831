package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.marginBlock
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.H4

@Composable
fun ErrorContent(title: String?, throwable: Throwable?, modifier: Modifier = Modifier) {
    val errorMessages = listOf(
        "Hunden løb af sporet..." to "Noget gik galt – prøv at opdatere siden! 🐕💨",
        "Pinden er væk!" to "Vi kunne ikke finde skoven – prøv igen om lidt. 🦴❌",
        "Snuden fandt ingen duft..." to "Vi kan desværre ikke indlæse hundeskovene. Prøv igen senere. 🐾🔍",
        "Hunden gravede sig ned..." to "Noget gik galt – skovene er midlertidigt ude af syne. 🐕🌲🚫",
        "Skoven er gemt væk..." to "Hundeskovene er ikke til at finde lige nu. Prøv at komme tilbage senere! 🐶🌳"
    )
    val message = remember { errorMessages.random() }
    val displayTitle = title ?: message.first
    val displayMessage = throwable?.message ?: message.second

    Column(modifier = modifier.fillMaxWidth(), horizontalAlignment = Alignment.CenterHorizontally, verticalArrangement = Arrangement.spacedBy(8.px)) {
        H3(Modifier.textAlign(TextAlign.Center).marginBlock().toAttrs()) {
            SpanText(
                text = displayTitle,
            )
        }

        SpanText(
            text = displayMessage,
            modifier = Modifier.textAlign(TextAlign.Center)
        )
    }
}