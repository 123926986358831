package pixelpark.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun AboutPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("About.md", mapOf())) {
        pixelpark.components.layouts.MarkdownLayout("Om os") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("vores-hundeskove-din-guide-til-hundeskove-i-danmark") }) {
                org.jetbrains.compose.web.dom.Text("Vores Hundeskove: Din Guide til Hundeskove i Danmark")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Velkommen til Vores Hundeskove")
                }
                org.jetbrains.compose.web.dom.Text(" – din ultimative guide til at udforske de smukkeste hundeskove i Danmark. Dette er et passionsprojekt skabt af en hundeelsker for alle andre hundeelskere. Vi har samlet information om hundeskove i hele landet, så du og din firbenede ven kan nyde naturen sammen og finde de bedste steder til eventyr.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("oplev-danmarks-skønneste-hundeskove") }) {
                org.jetbrains.compose.web.dom.Text("Oplev Danmarks Skønneste Hundeskove")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Danmark er fyldt med fantastiske naturoplevelser, og vi mener, at hundeskovene er en af de bedste måder at opleve landets skønhed på. Uanset om du bor i byen eller på landet, vil du finde en hundeskov, hvor din hund kan løbe frit og lege trygt. Fra Nordjyllands vilde klitlandskaber til Sjællands tætte skovområder – vores hjemmeside dækker det hele.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("vores-passion-for-natur-og-hundeliv") }) {
                org.jetbrains.compose.web.dom.Text("Vores Passion for Natur og Hundeliv")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Vores Hundeskove er drevet af en dyb kærlighed til både naturen og hundelivet. Vi ved, hvor vigtigt det er for hundeejere at have adgang til sikre og sjove områder, hvor deres hunde kan udfolde sig. Derfor har vi dedikeret os til at samle information om de bedste hundeskove i Danmark og dele vores erfaringer og tips med dig.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("find-din-nærmeste-hundeskov-i-app-en-til-android-og-ios") }) {
                org.jetbrains.compose.web.dom.Text("Find Din Nærmeste Hundeskov i App'en til Android og iOS")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Vi har gjort det nemt for dig at finde din nærmeste hundeskov. Brug vores interaktive kort i app'en til Android iOS eller søg blandt vores omfattende liste af hundeskove i hele Danmark. Hver hundeskov er nøje beskrevet med oplysninger om faciliteter, adgangsforhold og naturoplevelser, så du kan vælge det perfekte sted for dig og din hund.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("tag-ud-på-eventyr-i-naturen") }) {
                org.jetbrains.compose.web.dom.Text("Tag Ud på Eventyr i Naturen")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hos Vores Hundeskove handler det om at inspirere hundeejere til at komme ud og udforske naturen sammen med deres hunde. Vi opfordrer dig til at tage på eventyr, opleve nye steder og skabe minder, der varer ved. Uanset om du er til lange vandreture i skoven eller korte gåture langs stranden, har vi den rette hundeskov til dig.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Følg med på vores rejse, og opdag de bedste hundeskove i Danmark. Sammen kan vi skabe flere uforglemmelige oplevelser for vores hunde i den smukke danske natur.")
                }
            }
        }
    }
}
