package pixelpark.pages.region

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.coroutines.launch
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.asResource
import pixelpark.components.layouts.ParkListLayout
import pixelpark.model.PopulatedPark
import pixelpark.model.Region
import pixelpark.components.rememberResourceState
import pixelpark.localization.strings

@Page("/regioner/{regionId}/hundeskove")
@Composable
fun RegionParksPage(
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()
    val regionId = ctx.route.params.getValue("regionId")
    var region by remember { mutableStateOf<Region?>(null) }
    var parks by rememberResourceState<List<PopulatedPark>>()

    LaunchedEffect(regionId) {
        region = RegionApi.getRegion(regionId)
    }

    LaunchedEffect(region) {
        if (ctx.isExporting) return@LaunchedEffect
        parks = ParksApi.searchParksResult(regionId = region?.id ?: return@LaunchedEffect)
            .asResource()
    }

    ParkListLayout(
        title = region?.let {
            strings.display_name(region = it, excludeRegion = true)
        } ?: "",
        description = "",
        parks = parks
    )
}
