package pixelpark

import pixelpark.model.City
import pixelpark.model.Kommune
import pixelpark.model.Region

enum class Formatting { Normal, Bold }
data class FormattedString(val value: String, val formating: Formatting)
fun String.asBold() = FormattedString(this, Formatting.Bold)
fun String.asNormal() = FormattedString(this, Formatting.Normal)

val Kommune.simpleName: String get() {
    return this.name.split(" ").filterNot { it.lowercase() == "kommune" }
        .joinToString(" ")
}

interface PixelParkStrings {
    val title_website: String

    val action_search: String
    val hint_search_long: String
    val hint_search_short: String

    val title_park_details_error: String
    val title_park_details_loading: String
    val label_park_details_loading: String

    val title_grouping_callout: String
    val lines_grouping_callout: List<String>
    val label_grouping_callout: String

    val action_city_option: String
    val action_region_option: String
    val action_kommune_option: String

    val title_apps_callout: String
    val lines_apps_callout: List<String>
    val list_items_apps_callout: List<List<FormattedString>>

    val title_cities_callout: String
    val lines_cities_callout: List<String>
    val title_kommunes_callout: String
    val title_regions_callout: String

    val title_data_request: String
    val lines_data_request: List<String>
    val list_items_data_request: List<List<FormattedString>>
    val list_sub_items_data_request: List<String>

    val title_contact: String
    val lines_contact: List<String>
    val list_items_contact: List<List<FormattedString>>

    val title_links: String
    val title_about_us: String
    val title_about_vores_hundeskove: String
    val title_what_is_dog_park: String
    val title_rules_in_dog_park: String
    val title_privacy_policy: String
    val title_apps: String
    val label_pixel_memory: String

    val title_facilities_section: String
    val label_fenced: String
    val label_unfenced: String
    val label_feature_water: String
    val label_feature_trash: String
    val label_feature_agility: String
    val label_feature_tables: String
    val label_feature_toilet: String
    val label_feature_wet_area: String
    val label_feature_small_dogs_area: String
    val label_feature_accessible: String
    val action_directions: String
    val action_parking: String

    val title_description: String
    val title_adresse: String
    val title_photos: String
    val title_reviews: String
    val title_facilities: String
    val label_error_image_load: String

    val alt_mapbox: String
    val alt_mapbox_pin: String

    val action_close: String

    fun kommunes_callout_lines(kommuneCount: Int): List<String>
    fun regions_callout_lines(regionCount: Int): List<String>
    fun search_result(query: String): String
    fun city_display_name(city: City, compact: Boolean = false): String
    fun display_name(kommune: Kommune, excludeKommune: Boolean = false, compact: Boolean = false): String
    fun display_name(region: Region, excludeRegion: Boolean = true, compact: Boolean = false): String

    fun empty_city_list_lines(city: City): List<String>
    fun empty_search_result_title(query: String): String
    fun empty_search_result_lines(query: String): List<String>

}

class DanishStrings : PixelParkStrings {
    override val title_website: String = "Vores Hundeskove"

    override val action_search: String = "Søg"
    override val hint_search_long: String = "Søg efter by, postnummer eller område"
    override val hint_search_short: String = "Søg efter by eller postnummer"

    override val title_park_details_error: String = "Kunne ikke finde hundeskov"
    override val title_park_details_loading: String = "Eventyret er lige rundt om hjørnet"
    override val label_park_details_loading: String = "Vi graver detaljerne frem"

    override val title_grouping_callout: String = "560+ Hundeskove, indhegninger og fritløbsområder"
    override val lines_grouping_callout: List<String> = listOf(
        "Danmark er fyldt med hundeskove, og med mere end 560 destinationer er de ofte tættere på end man regner med.",
        "Her på siden kan du hurtigt finde netop de hundeskove, der passer til dine krav uanset, hvor du befinder dig."
    )
    override val label_grouping_callout: String = "Find hundeskove opdelt pr:"

    override val action_city_option: String = "By"
    override val action_region_option: String = "Region"
    override val action_kommune_option: String = "Kommune"

    override val title_apps_callout: String = "Vores Hundeskove er bedst som app!"
    override val lines_apps_callout: List<String> = listOf(
        "Er du klar til at gøre dine ture i naturen endnu bedre?",
        "Med vores moderne app kan du: "
    )
    override val list_items_apps_callout: List<List<FormattedString>> = listOf(
        listOf("Udforsk de bedste hundeskove".asBold(), ", tættest på dig via vores interaktive kort".asNormal()),
        listOf("Gem dine favoritter".asBold(), ", så du altid har dem lige ved hånden.".asNormal()),

        listOf("Filtrer".asBold(), " hundeskovene på bedømmelse, distance og faciliteter".asNormal()),
        listOf("Få ".asNormal(), "opdaterede beskrivelser".asBold(), " af faciliteter, adgangsforhold og lokale regler.".asNormal()),
    )

    override val title_cities_callout: String = "Hundeskove i de danske byer"
    override val lines_cities_callout: List<String> = listOf(
        "Danmark har et væld af hundeskove spredt over byer i hele landet, hvor du og din hund kan nyde friheden i naturen. Uanset hvor du bor eller er på besøg, er der altid en hundeskov i nærheden, hvor din hund kan løbe frit og socialisere med andre.",
        "Her præsenterer vi en liste over danske byer, der tilbyder hundeskove, så du nemt kan finde de bedste steder til en skøn gåtur med din hund."
    )

    override val title_kommunes_callout: String = "Hundeskove i de danske kommuner"
    override fun kommunes_callout_lines(kommuneCount: Int): List<String> = listOf(
        "Danmark byder på et stort udvalg af hundeskove, hvor din hund kan løbe frit og nyde naturen.",
        "Uanset hvilken kommune du bor i, er der en god chance for, at du kan finde en skov tæt på. Her er en oversigt over de $kommuneCount danske kommuner, som har hundeskove, så du nemt kan finde de bedste steder at tage din hund med ud i det fri."
    )

    override val title_regions_callout: String = "Hundeskove i de danske regioner"
    override fun regions_callout_lines(regionCount: Int): List<String> = listOf(
        "Leder du efter nye eventyr i din egen region eller skal du på besøge venner eller familie i en anden region?",
        "Uanset kan du her let finde hundeskove i Danmarks $regionCount store regioner. "
    )

    override val title_data_request: String = "Sletning af konto"
    override val lines_data_request: List<String> = listOf(
        "Vores Hundeskove respekterer dit privatliv og giver dig mulighed for at slette din konto.",
        "Hvis du ønsker at slette din konto, skal du følge nedenstående trin: "
    )
    override val list_items_data_request: List<List<FormattedString>> = listOf(
        listOf("Åbn din e-mail-klient og opret en ny e-mail.".asNormal()),
        listOf("Adresser e-mailen til \"SebastianRaskJepsen@gmail.com\"".asNormal()),

        listOf("Brug emnelinjen: \"Anmodning om kontosletning\".".asNormal()),
        listOf("Oplys følgende oplysninger i e-mailens brødtekst:".asNormal()),
    )
    override val list_sub_items_data_request: List<String> = listOf(
        "Din registrerede e-mailadresse",
        "En kort erklæring, der anmoder om sletning af din konto",
        "Information, der kan hjælpe os med at bekræfte din identitet",
    )

    override val title_contact: String = "Kontakt os"
    override val lines_contact: List<String> = listOf(
        "Vores Hundeskove modtager gerne ris, ros eller spørgsmål vedrørerende indholdet på siden og vores apps.",
        "Hvis du ønsker at kontakte os, kan du følge nedenstående trin: "
    )
    override val list_items_contact: List<List<FormattedString>> = listOf(
        listOf("Åbn din e-mail-klient og opret en ny e-mail.".asNormal()),
        listOf("Adresser e-mailen til \"SebastianRaskJepsen@gmail.com\"".asNormal()),

        listOf("Brug emnelinjen: \"Vores Hundeskove\".".asNormal()),
        listOf("Udfyld e-mailens brødtekst med ris, ros og/eller spørgsmål".asNormal()),
    )

    override val title_links: String = "Links"
    override val title_about_us: String = "Om os"
    override val title_about_vores_hundeskove: String = "Om Vores Hundeskove"
    override val title_what_is_dog_park: String = "Hvad er en hundeskov?"
    override val title_rules_in_dog_park: String = "Regler i hundeskove"
    override val title_privacy_policy: String = "Privatlivspolitik"
    override val title_apps: String = "Apps"
    override val label_pixel_memory: String = "Vores Hundeskove er udviklet til minde om mine mange eventyr med Pixel"

    override val title_facilities_section: String = "Faciliteter"
    override val label_fenced: String = "Indhegnet"
    override val label_unfenced: String = "Uden hegn"
    override val label_feature_water: String = "Vand"
    override val label_feature_trash: String = "Skraldespand"
    override val label_feature_agility: String = "Agility"
    override val label_feature_tables: String = "Borde"
    override val label_feature_toilet: String = "WC"
    override val label_feature_wet_area: String = "Vådområde"
    override val label_feature_small_dogs_area: String = "Småhundeområde"
    override val label_feature_accessible: String = "Handicapvenligt"
    override val action_directions: String = "Rute til Hundeskov"
    override val action_parking: String = "Rute til Parkering"

    override val title_description: String = "Beskrivelse"
    override val title_adresse: String = "Adresse"
    override val title_photos: String = "Billeder"
    override val title_reviews: String = "Anmeldelser"
    override val title_facilities: String = "Faciliteter"
    override val label_error_image_load: String = "Billedet kunne ikke indlæses"

    override val alt_mapbox: String = "Lokation på kort"
    override val alt_mapbox_pin: String = "Pin på kort"

    override val action_close: String = "Luk"

    override fun search_result(query: String): String = "Søgeresultat: $query"

    override fun city_display_name(city: City, compact: Boolean): String {
        val adverb = when {
            compact -> null
            else -> "i"
        }
        return listOfNotNull("Hundeskove", adverb, city.name).joinToString(" ")
    }

    override fun display_name(kommune: Kommune, excludeKommune: Boolean, compact: Boolean): String {
        val name = if (excludeKommune || compact) kommune.simpleName else kommune.name
        val adverb = when {
            compact -> null
            else -> "i"
        }
        return listOfNotNull("Hundeskove", adverb, name).joinToString(" ")
    }

    override fun display_name(region: Region, excludeRegion: Boolean, compact: Boolean): String {
        val name = if (excludeRegion || compact) region.simpleName else region.name
        val adverb = when {
            compact -> null
            region.island && excludeRegion -> "på"
            else -> "i"
        }
        return listOfNotNull("Hundeskove", adverb, name).joinToString(" ")
    }

    override fun empty_city_list_lines(city: City): List<String> = listOf(
        "Der er desværre ikke nogle registrerede hundeskove i ${city.name}.",
        "Du kan i stedet kigge på disse nærliggende byer for at finde en hundeskove til jeres næste eventyr:",
    )

    override fun empty_search_result_title(query: String): String = "Der er desværre ikke nogle registrerede hundeskove for: ${query}"
    override fun empty_search_result_lines(query: String): List<String> = listOf(
        "Dobbelttjek eventuelle taste og/eller stavefejl og prøv igen.",
        "Hvis du stadig ikke kan finde nogle resultater kan du i stedet finde hundeskove baseret på by, kommune eller region: "
    )
}