package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import pixelpark.model.ParkMapsReview
import pixelpark.model.authorDisplayName
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.textStyle
import kotlin.js.Date

@Composable
fun ReviewItem(review: ParkMapsReview, modifier: Modifier = Modifier) {
    val reviewDate = Date(review.time * 1000)
    val timeText = reviewDate.toLocaleDateString()

    Box(modifier) {
        Column(modifier = Modifier.fillMaxWidth().padding(leftRight = 16.px, topBottom = 8.px)) {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(8.px),
                modifier = Modifier.fillMaxWidth()
            ) {
                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start,
                    modifier = Modifier.weight(1f)
                ) {
                    SpanText(
                        text = review.authorDisplayName,
                        modifier = Modifier.textStyle(PixelParkTheme.typography.bodyLarge)
                    )
                    SpanText(
                        text = timeText,
                        modifier = Modifier.textStyle(PixelParkTheme.typography.bodyMedium)
                            .color(PixelParkTheme.colorScheme.onSurfaceVariant)
                    )
                }

                ReviewStatus(
                    stars = review.rating,
                    size = StatusSize.Medium,
                    modifier = Modifier
                )
            }
            SizeSpacer(8.px)

            SpanText(
                text = review.text,
                modifier = Modifier.textStyle(PixelParkTheme.typography.bodyLarge)
            )
        }
    }
}