package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.svg.Path
import com.varabyte.kobweb.compose.dom.svg.ViewBox
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.IconRenderStyle
import com.varabyte.kobweb.silk.components.icons.createIcon
import org.jetbrains.compose.web.css.px
import pixelpark.components.widgets.Icon.*

enum class Icon {
    Agility,
    Accessible,
    ChevronForward,
    DirectionsCar,
    Distance,
    FavouriteFilled,
    FavouriteOutlined,
    FenceOff,
    Fence,
    Filter,
    Forest,
    HeartBroken,
    Mail,
    Navigation,
    Star,
    Table,
    Toilet,
    Trash,
    Water,
    Wetland
}

@Composable
fun PixelParkIcon(icon: Icon, modifier: Modifier = Modifier) {
    when(icon) {
        Agility -> Agility(modifier)
        Accessible -> AccessibleIcon(modifier)
        ChevronForward -> ChevronForward(modifier)
        DirectionsCar -> DirectionsCar(modifier)
        Distance -> Distance(modifier)
        FavouriteFilled -> FavouriteFilled(modifier)
        FavouriteOutlined -> FavouriteOutlined(modifier)
        FenceOff -> FenceOff(modifier)
        Fence -> Fenced(modifier)
        Filter -> Filter(modifier)
        Forest -> Forest(modifier)
        HeartBroken -> HeartBroken(modifier)
        Mail -> Mail(modifier)
        Navigation -> Navigation(modifier)
        Star -> Star(modifier)
        Table -> Table(modifier)
        Toilet -> Toilet(modifier)
        Trash -> Trash(modifier)
        Water -> Water(modifier)
        Wetland -> Wetland(modifier)
    }
}

@Composable
fun Agility(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M169.859-485Q132-485 106-511.141t-26-64Q80-613 106.141-639t64-26Q208-665 234-638.859t26 64Q260-537 233.859-511t-64 26Zm185-170Q317-655 291-681.141t-26-64Q265-783 291.141-809t64-26Q393-835 419-808.859t26 64Q445-707 418.859-681t-64 26Zm250 0Q567-655 541-681.141t-26-64Q515-783 541.141-809t64-26Q643-835 669-808.859t26 64Q695-707 668.859-681t-64 26Zm185 170Q752-485 726-511.141t-26-64Q700-613 726.141-639t64-26Q828-665 854-638.859t26 64Q880-537 853.859-511t-64 26ZM266-75q-42 0-69-31.526T170-181q0-42 25.5-74.5T250-318q22-22 41-46.5t36-50.5q29-44 65-82t88-38q52 0 88.5 38t65.5 83q17 26 35.5 50t40.5 46q29 30 54.5 62.5T790-181q0 42.948-27 74.474Q736-75 694-75q-54 0-107-9t-107-9q-54 0-107 9t-107 9Z")
        }
    }
}

@Composable
fun AccessibleIcon(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M482.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5ZM725.825-80Q713-80 704.5-88.625T696-110v-179H482q-29.7 0-50.85-21.15Q410-331.3 410-361v-247q0-31.5 20-51.75T482.394-680q22.212 0 38.409 9T559-636q38 44 81 75.5t94 39.5q11 2 18.5 10.054 7.5 8.053 7.5 19.035Q760-478 750.5-469q-9.5 9-23.5 7-44-7-89-30.5T551-551v183h133q29.7 0 50.85 21.15Q756-325.7 756-296v186q0 12.75-8.675 21.375Q738.649-80 725.825-80ZM396-80q-83 0-139.5-56.5T200-276q0-68 49.5-125.5T380-468v61q-54 5-86.5 44.5T261-276q0 58 38.5 97t96.5 39q47 0 87-32.5t44-86.5h61q-8 80-66 129.5T396-80Z")
        }
    }
}

@Composable
fun ChevronForward(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M591-482 276-797q-11-11-11-27.5t11-28.5q12-12 28.5-12t28.5 12l329 329q9 9 13 20t4 22q0 11-4 22t-13 20L332-110q-12 12-28 11.5T276-111q-11-12-11.5-28t11.5-28l315-315Z")
        }
    }
}

@Composable
fun DirectionsCar(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M200-204v54q0 12.75-8.625 21.375T170-120h-20q-12.75 0-21.375-8.625T120-150v-324l85-256q5-14 16.5-22t26.5-8h464q15 0 26.5 8t16.5 22l85 256v324q0 12.75-8.625 21.375T810-120h-21q-12.75 0-21.375-8.625T759-150v-54H200Zm3-330h554l-55-166H258l-55 166Zm82.765 220Q309-314 324.5-329.75T340-368q0-23.333-15.75-39.667Q308.5-424 286-424q-23.333 0-39.667 16.265Q230-391.471 230-368.235 230-345 246.265-329.5q16.264 15.5 39.5 15.5ZM675-314q23.333 0 39.667-15.75Q731-345.5 731-368q0-23.333-16.265-39.667Q698.471-424 675.235-424 652-424 636.5-407.735q-15.5 16.264-15.5 39.5Q621-345 636.75-329.5T675-314Z")
        }
    }
}

@Composable
fun Distance(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M355-120q-65 0-110-45.531T200-275v-349q-35-13-57.5-41.263-22.5-28.264-22.5-64.404Q120-776 152.5-808t78-32q45.5 0 77.5 32.138 32 32.139 32 78.051Q340-694 317.5-665.5 295-637 260-624v349q0 39.188 27.5 67.094Q315-180 355.5-180t67.5-27.906q27-27.906 27-67.094v-410q0-65 45-110t110-45q65 0 110 45t45 110v349q35 13 57.5 41.365Q840-266.27 840-230q0 45-32.083 77.5Q775.833-120 730-120q-45 0-77.5-32.5T620-230q0-36.297 22.5-65.148Q665-324 700-336v-349q0-40-27.5-67.5T605-780q-40 0-67.5 27.5T510-685v410q0 63.938-45 109.469Q420-120 355-120ZM230.5-680q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T230-780q-20 0-35 14.375T180-730q0 20 15 35t35.5 15Zm500 500q20.5 0 35-15t14.5-35.5q0-20.5-14.375-35T730-280q-20 0-35 14.375T680-230q0 20 15 35t35.5 15ZM230-730Zm500 500Z")
        }
    }
}

@Composable
fun FavouriteFilled(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-308q5 0 8.5-1.5t7.5-3.5l314-205q5-3 7.5-8t2.5-11q0-13-11.5-20.5t-23.5.5L480-522 176-717q-12-8-24-1t-12 20q0 6 3 11.5t7 8.5l314 205q4 2 7.5 3.5t8.5 1.5Z")
        }
    }
}

@Composable
fun FavouriteOutlined(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("m439-156-53-49Q262-320 171-424.5T80-643q0-90 60.5-150.5T290-854q51 0 101 24.5t89 80.5q44-56 91-80.5t99-24.5q89 0 149.5 60.5T880-643q0 114-91 218.5T574-205l-53 49q-17 16-41 16t-41-16Zm15-527q-27-49-71-80t-93-31q-66 0-108 42.5T140-643q0 58 39 121.5T272-398q54 60 112 111.5t96 86.5q38-34 96-86t112-112.5q54-60.5 93-124T820-643q0-66-42.5-108.5T670-794q-50 0-93.5 30.5T504-683q-5 8-11 11.5t-14 3.5q-8 0-14.5-3.5T454-683Zm26 186Z")
        }
    }
}

@Composable
fun FenceOff(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox.sized(24, 24),
        renderStyle = IconRenderStyle.Stroke(2),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M12 12H4v4h12m4 0v-4h-4M6 16v4h4v-4m0-4v-2m0-4L8 4M6 6v6m8 4v4h4v-2m0-6V6l-2-2l-2 2v4M3 3l18 18")
        }
    }
}

@Composable
fun Fenced(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox.sized(24, 24),
        renderStyle = IconRenderStyle.Stroke(2),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M4 12v4h16v-4zm2 4v4h4v-4m0-4V6L8 4L6 6v6m8 4v4h4v-4m0-4V6l-2-2l-2 2v6")
        }
    }
}

@Composable
fun Filter(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M440-240q-17 0-28.5-11.5T400-280q0-17 11.5-28.5T440-320h80q17 0 28.5 11.5T560-280q0 17-11.5 28.5T520-240h-80ZM160-640q-17 0-28.5-11.5T120-680q0-17 11.5-28.5T160-720h640q17 0 28.5 11.5T840-680q0 17-11.5 28.5T800-640H160Zm120 200q-17 0-28.5-11.5T240-480q0-17 11.5-28.5T280-520h400q17 0 28.5 11.5T720-480q0 17-11.5 28.5T680-440H280Z")
        }
    }
}

@Composable
fun Forest(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M303-229H57q-18 0-27-16t2-31l157-227h-37q-18 0-27-16t2-31l208-295q9-13 25-13t25 13l95 135 95-135q9-13 25-13t25 13l208 295q11 15 2 31t-27 16h-36l156 227q11 15 2 31t-27 16H658v92q0 24-16.5 40.5T601-80q-24 0-41-17t-17-41v-91H418v92q0 24-16.5 40.5T361-80q-24 0-41-17t-17-41v-91Zm377-60h165L656-563h89L600-769l-80 115 73 104q11 15 2 31t-27 16h-36l148 214Zm-564 0h489L416-563h89L360-769 215-563h90L116-289Zm0 0h189-90 290-89 189-489Zm564 0H532h63-75 225-89 189-165Zm-137 60h115-115Zm121 0Z")
        }
    }
}

@Composable
fun HeartBroken(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M385-187Q283-288 222.5-352t-92-109.5Q99-507 89.5-542T80-620q0-92 64-156t156-64q45 0 87 16.5t75 47.5l-51 178q-5 14 4.5 26t24.5 12h81l-35 291q-1 8 6.5 9t9.5-6l89-295q5-14-4.5-26.5T562-600h-82l71-212q25-14 52.5-21t56.5-7q92 0 156 64t64 156q0 42-9 77t-40 80q-31 45-91.5 109.5T577-187q-40 39-96 39t-96-39Z")
        }
    }
}

@Composable
fun Mail(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm340-308q5 0 8.5-1.5t7.5-3.5l314-205q5-3 7.5-8t2.5-11q0-13-11.5-20.5t-23.5.5L480-522 176-717q-12-8-24-1t-12 20q0 6 3 11.5t7 8.5l314 205q4 2 7.5 3.5t8.5 1.5Z")
        }
    }
}

@Composable
fun Navigation(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M413-413 137-520q-10-4-14.5-12t-4.5-17q0-9 4.5-16t14.5-11l641-241q9-4 17.5-1.5T810-810q6 6 8.5 14.5T817-778L576-137q-4 10-11 14.5t-16 4.5q-9 0-17-4.5T520-137L413-413Z")
        }
    }
}

@Composable
fun Star(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("m233-120 65-281L80-590l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z")
        }
    }
}

@Composable
fun Table(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M256-420h450l-13-100H270l-14 100Zm-61 260q-14 0-22.5-10t-6.5-24l44-326h-89q-20 0-31.5-16T83-571l57-200q4-13 14-21t24-8h606q14 0 24 8t14 21l57 200q5 19-6.5 35T841-520h-88l43 326q2 14-6.5 24T767-160q-11 0-19-7.5T738-186l-24-174H248l-24 174q-2 11-10 18.5t-19 7.5Z")
        }
    }
}

@Composable
fun Toilet(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M242-80q-12.75 0-21.375-8.625T212-110v-270h-20q-12.75 0-21.375-8.625T162-410v-198q0-29 21.5-50.5t50.242-21.5h104.516Q367-680 388.5-658.5 410-637 410-608v198q0 12.75-8.625 21.375T380-380h-20v270q0 12.75-8.625 21.375T330-80h-88Zm418 0q-12.75 0-21.375-8.625T630-110v-210h-65q-14 0-23-12t-5-26l75-273q7.216-23.593 26.608-36.296Q658-680 684-680q26 0 45.392 12.704Q748.784-654.593 756-631l75 273q4 14-5 26t-23 12h-65v210q0 12.75-8.625 21.375T708-80h-48ZM286.08-734q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Zm398 0q-30.08 0-51.58-21.42-21.5-21.421-21.5-51.5 0-30.08 21.42-51.58 21.421-21.5 51.5-21.5 30.08 0 51.58 21.42 21.5 21.421 21.5 51.5 0 30.08-21.42 51.58-21.421 21.5-51.5 21.5Z")
        }
    }
}

@Composable
fun Trash(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M261-120q-24.75 0-42.375-17.625T201-180v-570h-11q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T190-810h158q0-13 8.625-21.5T378-840h204q12.75 0 21.375 8.625T612-810h158q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T770-750h-11v570q0 24.75-17.625 42.375T699-120H261Zm106-146h60v-399h-60v399Zm166 0h60v-399h-60v399Z")
        }
    }
}

@Composable
fun Water(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M480-80q-137 0-228.5-94T160-408q0-64 29-127t72.5-121q43.5-58 94-108.5T450-854q7-6 14.5-8.5T480-865q8 0 15.5 2.5T510-854q44 39 94.5 89.5t94 108.5Q742-598 771-535t29 127q0 140-91.5 234T480-80Zm-1-128q16 0 24.5-5.5T512-230q0-11-8.5-17t-25.5-6q-42 0-85.5-26.5T337-373q-2-9-9-14.5t-15-5.5q-11 0-17 8.5t-4 17.5q15 84 71 121.5T479-208Z")
        }
    }
}

@Composable
fun Wetland(modifier: Modifier = Modifier) {
    createIcon(
        viewBox = ViewBox(0, -960, 960, 960),
        renderStyle = IconRenderStyle.Fill(),
        attrs = modifier.toAttrs()
    ) {
        Path {
            d("M80-441q0-11 7.5-21t21.5-15q23-9 44.5-24.5T210-517q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t70.5-27q35 0 56.5 15.5T851-477q14 5 21.5 15t7.5 21q0 13-9 21t-20 5q-30-8-50-25t-51-17q-38 0-64 27t-71 27q-45 0-71-27t-64-27q-38 0-64.5 27T344-403q-45 0-70.5-27T210-457q-31 0-51 17t-50 25q-11 3-20-5t-9-21Zm0 160q0-11 7.5-21t21.5-15q23-9 44.5-24.5T210-357q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t70.5-27q35 0 56.5 15.5T851-317q14 5 21.5 15t7.5 21q0 13-9 21t-20 5q-30-8-50-25t-51-17q-38 0-64 27t-71 27q-45 0-71-27t-64-27q-38 0-64.5 27T344-243q-45 0-70.5-27T210-297q-31 0-51 17t-50 25q-11 3-20-5t-9-21Zm0-320q0-11 7.5-21t21.5-15q23-9 44.5-24.5T210-677q45 0 70 27t64 27q39 0 65-27t71-27q45 0 70.5 27t64.5 27q39 0 64.5-27t70.5-27q35 0 56.5 15.5T851-637q14 5 21.5 15t7.5 21q0 13-9 21t-20 5q-30-8-50-25t-51-17q-38 0-64 27t-71 27q-45 0-71-27t-64-27q-38 0-64.5 27T344-563q-45 0-70.5-27T210-617q-31 0-51 17t-50 25q-11 3-20-5t-9-21Z")
        }
    }
}
