package pixelpark.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSLengthOrPercentageNumericValue
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.overlay.Overlay
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H3
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.Resource
import pixelpark.Status.*
import pixelpark.api.ParksApi
import pixelpark.asResource
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.rememberResourceState
import pixelpark.components.widgets.*
import pixelpark.localization.strings
import pixelpark.model.ParkFacility
import pixelpark.model.PopulatedPark
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.surface
import pixelpark.utils.*

@Page("/hundeskove/detaljer")
@Composable
fun ParkDetailsPage() {
    val ctx = rememberPageContext()
    val params = PixelParkRoutes.ParkDetails.ParkDetailParams(ctx.route.queryParams)
    var park by rememberResourceState<PopulatedPark>()

    LaunchedEffect(params) {
        if (ctx.isExporting) return@LaunchedEffect
        if (params.id.isBlank()) {
            park = Resource.error(IllegalStateException("Invalid park id"))
            return@LaunchedEffect
        }

        park = ParksApi.getParkResult(id = params.id)
            .asResource()
    }

    VoresPageLayout(
        params.title,
        header = { ParkDetailsPageHeader(params, park, modifier = it) },
        content = { ParkDetails(park) }
    )
}

@Composable
private fun ParkDetails(
    resource: Resource<PopulatedPark>,
    strings: PixelParkStrings = strings()
) {
    ResourceView(
        resource = resource,
        errorTitle = strings.title_park_details_error,
        loadingContent = {
            ScreenLoadingIndicator(strings.title_park_details_loading, message = strings.label_park_details_loading)
        },
        modifier = Modifier.fillMaxWidth()
    ) {
        val park = it ?: return@ResourceView
        val breakpoint = rememberBreakpoint()
        val multiColumn = breakpoint >= Breakpoint.MD

        val gap = if (multiColumn) 12.px else 8.px
        val baseCardModifier = Modifier
            .thenIf(multiColumn) {
                Modifier.padding(gap)
            }
            .thenIf(!multiColumn) {
                Modifier.padding(topBottom = gap)
            }
            .fillMaxWidth()

        Row(
            modifier = Modifier
                .padding(top = gap)
                .fillMaxWidth(),
            verticalAlignment = Alignment.Top
        ) {
            Column(Modifier.weight(1f)) {
                park.description?.base?.let { description ->
                    DescriptionCard(
                        description = description,
                        modifier = baseCardModifier
                    )
                }

                if (!multiColumn) {
                    AdresseCard(
                        park = park,
                        modifier = baseCardModifier
                    )
                }

                if (!multiColumn) {
                    FacilityCard(
                        park = park,
                        modifier = baseCardModifier
                    )
                }

                ImagesCard(
                    park = park,
                    modifier = baseCardModifier
                )

                ReviewsCard(
                    park = park,
                    modifier = baseCardModifier
                )
            }

            if (multiColumn) {
                val width = 20.cssRem
                Column(modifier = Modifier.minWidth(width).width(width)) {
                    AdresseCard(
                        park = park,
                        modifier = baseCardModifier
                    )
                    FacilityCard(
                        park = park,
                        modifier = baseCardModifier
                    )
                }
            }
        }
    }
}

@Composable
private fun ReviewsCard(
    park: PopulatedPark,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    val reviews = park.reviews
    if (reviews.isEmpty()) return

    CardSection(
        title = strings.title_reviews,
        content = {
            Column {
                reviews.forEachIndexed { index, parkMapsReview ->
                    ReviewItem(parkMapsReview, Modifier.fillMaxWidth())

                    if (index != reviews.lastIndex) {
                        Divider()
                    }
                }
            }
        },
        horizontalPadding = 0.px,
        modifier = modifier,
    )
}


@Composable
private fun ImagesCard(
    park: PopulatedPark,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()

    val images = park.imageIds
    if (images.isEmpty()) {
        return
    }

    var overlayImage by remember { mutableStateOf<String?>(null) }
    if (overlayImage != null) {
        Overlay(Modifier.onClick { overlayImage = null }) {
            Box(
                contentAlignment = Alignment.Center,
                modifier = Modifier.fillMaxSize().padding(32.px)
            ) {
                Column(horizontalAlignment = Alignment.CenterHorizontally) {
                    Image(
                        src = overlayImage ?: "",
                        modifier = Modifier
                            .objectFit(ObjectFit.Cover)
                            .thenIfMobile {
                                Modifier.fillMaxWidth()
                            }
                            .thenIfNotMobile {
                                Modifier.maxWidth(400.px)
                            }
                            .pixelParkClip(RectF(32.px))
                    )
                    SizeSpacer(16.px)
                    TextButton(strings.action_close, modifier = Modifier.onClick { overlayImage = null })
                }
            }
        }
    }

    CardSection(
        title = strings.title_photos,
        content = {
            val spacing = 16.px
            Row(
                horizontalArrangement = Arrangement.spacedBy(spacing),
                modifier = Modifier.fillMaxWidth()
                    .flexWrap(FlexWrap.Wrap)
            ) {
                images.forEach { entry ->
                    var imageBase64 by rememberResourceState<String>()

                    LaunchedEffect(entry) {
                        if (ctx.isExporting) return@LaunchedEffect
                        imageBase64 = ParksApi.getImageResult(id = entry).asResource()
                    }

                    Box(
                        contentAlignment = Alignment.Center,
                        modifier = Modifier.size(128.px)
                            .pixelParkClip(RectF(16.px))
                            .surface(PixelParkTheme.colorScheme.inverseSurface)
                            .margin(bottom = spacing)
                    ) {
                        when (imageBase64.status) {
                            SUCCESS -> {
                                val dataString = "data:image/jpg;base64,${imageBase64.data}"
                                Image(
                                    src = dataString,
                                    modifier = Modifier
                                        .objectFit(ObjectFit.Cover)
                                        .fillMaxSize()
                                        .onClick {
                                            overlayImage = dataString
                                        }
                                )
                            }

                            ERROR -> SpanText(
                                text = strings.label_error_image_load,
                                modifier = Modifier.textAlign(TextAlign.Center).padding(16.px)
                            )

                            LOADING -> PawLoader(
                                pawCount = 4,
                                pawSize = 1.5.cssRem,
                                color = PixelParkTheme.colorScheme.inverseOnSurface,
                                modifier = Modifier
                                    .rotate(45.deg * -1)
                            )
                        }
                    }
                }
            }
        },
        modifier = modifier
    )
}

@Composable
private fun FacilityCard(
    park: PopulatedPark,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    CardSection(
        title = strings.title_facilities,
        content = {
            val spacing = 8.px
            Row(
                horizontalArrangement = Arrangement.spacedBy(spacing),
                modifier = Modifier.fillMaxWidth()
                    .flexWrap(FlexWrap.Wrap)
            ) {
                ParkFacility.entries.forEach { entry ->
                    val enabled = entry in park.facilities

                    Status(
                        icon = { PixelParkIcon(entry.icon, Modifier.fillMaxSize()) },
                        text = entry.displayName,
                        emphasis = if (enabled) StatusEmphasis.High else StatusEmphasis.Low,
                        size = StatusSize.Large,
                        modifier = Modifier.margin(bottom = spacing)
                    )
                }
            }
        },
        modifier = modifier
    )
}

@Composable
private fun DescriptionCard(
    description: String,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    CardSection(
        title = strings.title_description,
        content = {
            SpanText(description)
        },
        modifier = modifier
    )
}

@Composable
private fun AdresseCard(
    park: PopulatedPark,
    modifier: Modifier = Modifier,
    strings: PixelParkStrings = strings()
) {
    val mapsImage = park.locationImageUrl()

    CardSection(
        title = strings.title_adresse,
        header = header@{
            if (mapsImage == null) {
                return@header
            }
            ParkLocationImage(
                park,
                modifier = Modifier
                    .fillMaxWidth()
                    .heightIn(max = 200.px)
            )
        },
        content = {
            SpanText(park.displayAddress)

            SizeSpacer(8.px)

            Column(Modifier.fillMaxWidth(), verticalArrangement = Arrangement.spacedBy(8.px)) {
                park.location.coordinates?.let { latLon ->
                    TextButton(
                        strings.action_directions,
                        modifier = Modifier
                            .fillMaxWidth()
                            .openNavigationOnClick(latLon.latitude, latLon.longitude)
                    )
                }

                park.parkData.parking?.location?.coordinates?.let { latLon ->
                    TextButton(
                        strings.action_parking,
                        modifier = Modifier
                            .fillMaxWidth()
                            .openNavigationOnClick(latLon.latitude, latLon.longitude)
                    )
                }
            }
        },
        modifier = modifier
    )
}

@Composable
fun CardSection(
    title: String,
    content: @Composable () -> Unit,
    header: (@Composable () -> Unit)? = null,
    horizontalPadding: CSSLengthOrPercentageNumericValue = 16.px,
    titleHorizontalPadding: CSSLengthOrPercentageNumericValue = 16.px,
    verticalPadding: CSSLengthOrPercentageNumericValue = 16.px,
    modifier: Modifier = Modifier,
) {
    val colors = PixelParkTheme.colorScheme
    val cornerRadius = 24.px

    Box(modifier) {
        Surface(
            modifier = Modifier
                .fillMaxWidth()
                .surface(colors.surfaceVariant)
                .pixelParkClip(RectF(cornerRadius))
        ) {
            Column(
                Modifier
                    .fillMaxWidth()
            ) {
                header?.invoke()

                H3(
                    Modifier.marginBlock().padding(leftRight = titleHorizontalPadding, top = verticalPadding).toAttrs()
                ) {
                    SpanText(title)
                }

                SizeSpacer(8.px)

                Column(
                    Modifier
                        .fillMaxWidth()
                        .padding(leftRight = horizontalPadding, bottom = verticalPadding)
                ) {
                    content()
                }
            }
        }
    }
}

@Composable
private fun ParkDetailsPageHeader(
    params: PixelParkRoutes.ParkDetails.ParkDetailParams,
    resource: Resource<PopulatedPark>,
    modifier: Modifier,
) {
    ExpandedPageHeader(
        minHeight = 15.cssRem,
        content = {
            Box(
                modifier = Modifier
                    .thenIfMobile {
                        Modifier.padding(leftRight = 16.px)
                    }
                    .thenIfNotMobile {
                        Modifier.padding(leftRight = 64.px)
                    }
                    .fillMaxWidth()
                    .fillMaxHeight(),
                contentAlignment = Alignment.Center
            ) {
                Column(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 3.cssRem),
                    horizontalAlignment = Alignment.Start
                ) {
                    val park = resource.data
                    H1(Modifier.marginBlock().toAttrs()) {
                        SpanText(park?.title?.base ?: params.title ?: "Hundeskov")
                    }

                    SizeSpacer(16.px)

                    park?.let {
                        ParkStatusRow(it, showFavouriteStatus = true, StatusSize.Large)
                    }
                }
            }
        },
        iconOnly = false,
        modifier = modifier
    )
}