package pixelpark.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.core.*
import com.varabyte.kobwebx.markdown.*

@Page
@Composable
fun DefinitionPage() {
    CompositionLocalProvider(LocalMarkdownContext provides MarkdownContext("Definition.md", mapOf())) {
        pixelpark.components.layouts.MarkdownLayout("Hvad er en Hundeskov?") {
            org.jetbrains.compose.web.dom.H1(attrs = { id("hvad-er-en-hundeskov") }) {
                org.jetbrains.compose.web.dom.Text("Hvad er en Hundeskov?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("En ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("hundeskov")
                }
                org.jetbrains.compose.web.dom.Text(" er et særligt afgrænset område i naturen, hvor hunde kan løbe frit uden snor og udforske omgivelserne i sikkerhed. Disse skove er skabt med henblik på at give hunde mulighed for at motionere og lege i naturen, mens deres ejere kan nyde en afslappende gåtur i skovens omgivelser. Hundeskove findes over hele ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Danmark")
                }
                org.jetbrains.compose.web.dom.Text(", og de er blevet et populært udflugtsmål for mange hundeejere, der ønsker at give deres firbenede venner en sjov og aktiv oplevelse i det fri.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("hvorfor-vælge-en-hundeskov") }) {
                org.jetbrains.compose.web.dom.Text("Hvorfor Vælge en Hundeskov?")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hundeskove er perfekte for hunde, der elsker at bevæge sig frit og interagere med andre hunde. I en hundeskov kan din hund opleve:")
            }
            org.jetbrains.compose.web.dom.Ul {
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Frihed")
                    }
                    org.jetbrains.compose.web.dom.Text(" til at løbe uden snor i et sikkert, indhegnet område.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Socialisering")
                    }
                    org.jetbrains.compose.web.dom.Text(" med andre hunde, hvilket er vigtigt for deres mentale og fysiske sundhed.")
                }
                org.jetbrains.compose.web.dom.Li {
                    org.jetbrains.compose.web.dom.B {
                        org.jetbrains.compose.web.dom.Text("Naturoplevelser")
                    }
                    org.jetbrains.compose.web.dom.Text(", hvor de kan snuse til nye dufte, grave og udforske omgivelserne.")
                }
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("For hundeejere er hundeskove også et fantastisk sted at møde andre ligesindede og nyde den danske natur på en måde, der både er afslappende og berigende.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("hundeskove-i-danmark") }) {
                org.jetbrains.compose.web.dom.Text("Hundeskove i Danmark")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Danmark har mange forskellige hundeskove spredt ud over hele landet. Fra de tætte skove på Sjælland til de åbne områder i Jylland, er der en hundeskov for enhver smag. Uanset hvor du bor, er der sandsynligvis en hundeskov i nærheden, hvor du og din hund kan opleve naturen sammen.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hundeskove i Danmark er typisk velholdte og har faciliteter som affaldsposer og vandposter, så det er nemt at tage vare på din hund under besøget. Mange hundeskove har også bænke og picnicområder, hvor du kan slappe af, mens din hund leger.")
            }
            org.jetbrains.compose.web.dom.H2(attrs = { id("oplev-naturen-med-din-hund") }) {
                org.jetbrains.compose.web.dom.Text("Oplev Naturen med Din Hund")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("At besøge en hundeskov er ikke bare godt for din hund – det er også en fantastisk måde at komme ud og nyde den danske natur på. Uanset årstiden byder hundeskovene på smukke landskaber, fredelige omgivelser og masser af frisk luft.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.Text("Hos ")
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Vores Hundeskove")
                }
                org.jetbrains.compose.web.dom.Text(" har vi gjort det til vores mission at hjælpe hundeejere med at finde de bedste hundeskove i Danmark. Vores hjemmeside giver dig al den information, du har brug for, så du kan vælge den perfekte hundeskov til dig og din hund.")
            }
            org.jetbrains.compose.web.dom.P {
                org.jetbrains.compose.web.dom.B {
                    org.jetbrains.compose.web.dom.Text("Tag din hund med på eventyr i en hundeskov nær dig, og oplev glæden ved naturen sammen!")
                }
            }
        }
    }
}
