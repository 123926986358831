package pixelpark.components.widgets

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.navigation.LinkStyle
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.animation.Keyframes
import com.varabyte.kobweb.silk.style.animation.toAnimation
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.shapes.Rect
import com.varabyte.kobweb.silk.theme.shapes.clip
import org.jetbrains.compose.web.css.*
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.contentColorFor

val CountTextKeyframes = Keyframes {
    from { Modifier.opacity(0) }
    to { Modifier.opacity(1) }
}

@Composable
fun CountTextButton(
    count: Int?,
    text: String,
    modifier: Modifier = Modifier,
    delayFactor: Int = 0,
    backgroundColor: Color = PixelParkTheme.colorScheme.secondaryContainer,
    contentColor: Color = contentColorFor(backgroundColor),
    countBackgroundColor: Color = PixelParkTheme.colorScheme.primary,
    countContentColor: Color = contentColorFor(countBackgroundColor)
) {
    var countState by remember { mutableStateOf(count) }

    val cornerRadius = 12.px
    val horizontalPadding = 12.px

    LaunchedEffect(count) {
        countState = count
    }

    Row(
        modifier = LinkStyle.toModifier(TextButtonLinkVariant)
            .then(modifier)
            .backgroundColor(backgroundColor)
            .color(contentColor)
            .fontWeight(FontWeight.Medium)
            .height(48.px)
            .clip(Rect(cornerRadius)),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .backgroundColor(countBackgroundColor)
                .color(countContentColor)
                .aspectRatio(1.1)
                .clip(Rect(cornerRadius))
                .fillMaxHeight()

        ) {
            SpanText(
                text = countState?.toString() ?: "",
                modifier = Modifier.opacity(0)
                    .animation(
                        CountTextKeyframes.toAnimation(
                            duration = 750.ms,
                            // Don't want this to take forever for longs lists.
                            delay = delayFactor.coerceIn(0, 15) * 100.ms,
                            timingFunction = AnimationTimingFunction.EaseInOut,
                            fillMode = AnimationFillMode.Forwards,
                            playState = if (countState != null) AnimationPlayState.Running else AnimationPlayState.Paused
                        ),
                    )
            )
        }

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.padding(leftRight = horizontalPadding).fillMaxWidth()
        ) {
            SpanText(text)
        }
    }

}