package pixelpark.theme

import com.varabyte.kobweb.compose.ui.graphics.Color

// Generated on https://m3.material.io/theme-builder#/custom
val seed = Color.argb(0xFF228B22)

val md_theme_light_primary = Color.argb(0xFF2d6b27)
val md_theme_light_onPrimary = Color.argb(0xFFFFFFFF)
val md_theme_light_primaryContainer = Color.argb(0xFF87E679)
val md_theme_light_onPrimaryContainer = Color.argb(0xFF002201)
val md_theme_light_secondary = Color.argb(0xFF53634E)
val md_theme_light_onSecondary = Color.argb(0xFFFFFFFF)
val md_theme_light_secondaryContainer = Color.argb(0xFFD6E8CD)
val md_theme_light_onSecondaryContainer = Color.argb(0xFF111F0F)
val md_theme_light_tertiary = Color.argb(0xFF386569)
val md_theme_light_onTertiary = Color.argb(0xFFFFFFFF)
val md_theme_light_tertiaryContainer = Color.argb(0xFFBCEBEF)
val md_theme_light_onTertiaryContainer = Color.argb(0xFF002022)
val md_theme_light_error = Color.argb(0xFFBA1A1A)
val md_theme_light_errorContainer = Color.argb(0xFFFFDAD6)
val md_theme_light_onError = Color.argb(0xFFFFFFFF)
val md_theme_light_onErrorContainer = Color.argb(0xFF410002)
val md_theme_light_background = Color.argb(0xFFFCFDF6)
val md_theme_light_onBackground = Color.argb(0xFF1A1C19)
val md_theme_light_surface = Color.argb(0xFFFCFDF6)
val md_theme_light_onSurface = Color.argb(0xFF1A1C19)
val md_theme_light_surfaceVariant = Color.argb(0xFFDFE4D8)
val md_theme_light_onSurfaceVariant = Color.argb(0xFF42493F)
val md_theme_light_outline = Color.argb(0xFF73796E)
val md_theme_light_inverseOnSurface = Color.argb(0xFFF1F1EB)
val md_theme_light_inverseSurface = Color.argb(0xFF2F312D)
val md_theme_light_inversePrimary = Color.argb(0xFF77DD6A)
val md_theme_light_shadow = Color.argb(0xFF000000)
val md_theme_light_surfaceTint = Color.argb(0xFF006E0C)
val md_theme_light_outlineVariant = Color.argb(0xFFC2C8BC)
val md_theme_light_scrim = Color.argb(0xFF000000)

val md_theme_dark_primary = Color.argb(0xFF77DD6A)
val md_theme_dark_onPrimary = Color.argb(0xFF003A03)
val md_theme_dark_primaryContainer = Color.argb(0xFF005307)
val md_theme_dark_onPrimaryContainer = Color.argb(0xFF92FA83)
val md_theme_dark_secondary = Color.argb(0xFFBBCCB2)
val md_theme_dark_onSecondary = Color.argb(0xFF263422)
val md_theme_dark_secondaryContainer = Color.argb(0xFF3C4B37)
val md_theme_dark_onSecondaryContainer = Color.argb(0xFFD6E8CD)
val md_theme_dark_tertiary = Color.argb(0xFFA0CFD3)
val md_theme_dark_onTertiary = Color.argb(0xFF00363A)
val md_theme_dark_tertiaryContainer = Color.argb(0xFF1E4D51)
val md_theme_dark_onTertiaryContainer = Color.argb(0xFFBCEBEF)
val md_theme_dark_error = Color.argb(0xFFFFB4AB)
val md_theme_dark_errorContainer = Color.argb(0xFF93000A)
val md_theme_dark_onError = Color.argb(0xFF690005)
val md_theme_dark_onErrorContainer = Color.argb(0xFFFFDAD6)
val md_theme_dark_background = Color.argb(0xFF1A1C19)
val md_theme_dark_onBackground = Color.argb(0xFFE2E3DD)
val md_theme_dark_surface = Color.argb(0xFF1A1C19)
val md_theme_dark_onSurface = Color.argb(0xFFE2E3DD)
val md_theme_dark_surfaceVariant = Color.argb(0xFF42493F)
val md_theme_dark_onSurfaceVariant = Color.argb(0xFFC2C8BC)
val md_theme_dark_outline = Color.argb(0xFF8C9387)
val md_theme_dark_inverseOnSurface = Color.argb(0xFF1A1C19)
val md_theme_dark_inverseSurface = Color.argb(0xFFE2E3DD)
val md_theme_dark_inversePrimary = Color.argb(0xFF006E0C)
val md_theme_dark_shadow = Color.argb(0xFF000000)
val md_theme_dark_surfaceTint = Color.argb(0xFF77DD6A)
val md_theme_dark_outlineVariant = Color.argb(0xFF42493F)
val md_theme_dark_scrim = Color.argb(0xFF000000)


