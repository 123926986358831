package pixelpark.pages.city

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import pixelpark.PixelParkRoutes
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.widgets.CountTextButton
import pixelpark.localization.strings
import pixelpark.model.City
import pixelpark.pages.kommune.rememberRegionEntryListTransitionModifier
import pixelpark.utils.isMobile
import pixelpark.utils.thenIfMobile

@Page("/byer")
@Composable
fun CitiesPage() {
    VoresPageLayout(subtitle = null) {
        CitiesCallOut()
    }
}

@Composable
fun CitiesCallOut(
    strings: PixelParkStrings = strings(),
    modifier: Modifier = Modifier
) {
    var cities by remember { mutableStateOf<List<City>>(emptyList()) }
    var parkCountByCityName by remember { mutableStateOf<Map<String, Int>>(emptyMap()) }

    LaunchedEffect(Unit) {
        launch {
            cities = RegionApi.getCities()
        }
        launch {
            parkCountByCityName = ParksApi.getParkCountByCityNameResult().getOrDefault(emptyMap())
        }
    }

    Box(
        contentAlignment = Alignment.TopCenter,
        modifier = modifier
            .fillMaxHeight()
            .fillMaxWidth()
    ) {
        Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {
            H1 {
                Text(strings.title_cities_callout)
            }

            strings.lines_cities_callout.forEach {
                P { Text(it) }
            }

            Box(Modifier.size(1.cssRem))

            CityListLayout(cities, parkCountByCityName)
        }
    }
}

@Composable
fun CityListLayout(
    cities: List<City>,
    parkCountByCityName: Map<String, Int>,
    strings: PixelParkStrings = strings()
) {
    val listTransitionModifier = rememberRegionEntryListTransitionModifier(cities)
    Column(listTransitionModifier, verticalArrangement = Arrangement.spacedBy(8.px)) {
        cities.forEachIndexed { index, city ->
            Link(
                path = PixelParkRoutes.CityParks(city).route,
                modifier = Modifier.thenIfMobile { Modifier.fillMaxWidth() }
            ) {
                val breakpoint = rememberBreakpoint()
                val count = parkCountByCityName[city.name]
                CountTextButton(
                    count = count,
                    text = strings.city_display_name(city, compact = breakpoint.isMobile()),
                    delayFactor = index
                )
            }
        }
    }
}
