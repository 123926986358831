package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.ReadOnlyComposable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.px
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.contentColorFor
import pixelpark.theme.textStyle
import pixelpark.theme.with

object ContentAlpha {
    const val High = 1.0f
    const val Medium = 0.6f
}

sealed class StatusEmphasis(val alpha: Float) {

    val backgroundColor: Color
        @ReadOnlyComposable @Composable
        get() {
            val colors = PixelParkTheme.colorScheme
            return when (this) {
                High -> colors.primaryContainer
                Medium, Low -> colors.surfaceVariant
                is Custom -> this._backgroundColor
            }
        }

    val contentColor: Color
        @ReadOnlyComposable @Composable
        get() = when (this) {
            is Custom -> this._contentColor
            else -> contentColorFor(backgroundColor)
        }

    data object High : StatusEmphasis(ContentAlpha.High)
    data object Medium : StatusEmphasis(ContentAlpha.High)
    data object Low : StatusEmphasis(ContentAlpha.Medium)
    data class Custom(val _backgroundColor: Color, val _contentColor: Color) : StatusEmphasis(ContentAlpha.High)
}

enum class StatusSize {
    Large, Medium
}

@Composable
fun Status(
    icon: @Composable () -> Unit,
    text: String?,
    emphasis: StatusEmphasis,
    size: StatusSize,
    secondaryText: String? = null,
    modifier: Modifier = Modifier,
) {
    val textStyle = when (size) {
        StatusSize.Large -> PixelParkTheme.typography.titleMedium
        StatusSize.Medium -> PixelParkTheme.typography.bodyMedium
    }

    val iconSize = when (size) {
        StatusSize.Large -> 20.px
        StatusSize.Medium -> 16.px
    }

    val spacer = when (size) {
        StatusSize.Large -> 4.px
        StatusSize.Medium -> 2.px
    }

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .padding(topBottom = 4.px, leftRight = 8.px)
            .backgroundColor(emphasis.backgroundColor)
            .pixelParkClip(RectF(24.px))
    ) {
        Box(
            modifier = Modifier
                .size(iconSize)
                .color(emphasis.contentColor.with(emphasis.alpha))
        ) {
            icon()
        }

        text?.let {
            SizeSpacer(spacer)
            SpanText(it, Modifier.textStyle(textStyle).color(emphasis.contentColor.with(emphasis.alpha)))
        }

        secondaryText?.let {
            SizeSpacer(spacer)

            SpanText(it, Modifier.textStyle(textStyle).color(emphasis.contentColor.with(ContentAlpha.Medium)))
        }
    }
}
