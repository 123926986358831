package pixelpark.model

import kotlinx.serialization.Serializable
import pixelpark.util.asDisplayId

@Serializable
data class RegionEntry(
    val region: Region,
    val kommune: Kommune,
    val postNumber: PostNumber
)

@Serializable
data class Region(val id: String, val name: String) {
    val displayId: String = name.asDisplayId
    val simpleName: String get() = name.split(" ").last()
    val island: Boolean = id == "1085" // Sjælland
}

@Serializable
data class Kommune(val id: String, val name: String) {
    val displayId: String = name.asDisplayId
}

@Serializable
data class PostNumber(val name: String, val postNumber: String) {
    val displayId: String = name.asDisplayId
}

@Serializable
data class City(val name: String, val postNumbers: List<String>) {
    val displayId: String = name.asDisplayId
}

fun Iterable<PostNumber>.toCities() = this.groupBy { it.name }
    .map { keyValue -> City(keyValue.key, keyValue.value.map { it.postNumber }.distinct().sorted()) }

fun Iterable<RegionEntry>.cities() = this.map { it.postNumber }.toCities()
