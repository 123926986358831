package pixelpark.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize


@Composable
fun MarkdownLayout(title: String, content: @Composable () -> Unit) {
    VoresPageLayout(title) {
        Column(Modifier.fillMaxSize(), horizontalAlignment = Alignment.Start) {
            content()
        }
    }
}
