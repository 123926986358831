package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.css.functions.CSSImage
import com.varabyte.kobweb.compose.css.functions.linearGradient
import com.varabyte.kobweb.compose.css.functions.url
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.vars.color.ColorVar
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitLengthOrPercentage
import org.jetbrains.compose.web.css.px
import pixelpark.components.sections.NavHeader
import pixelpark.theme.PixelParkTheme
import pixelpark.theme.with
import pixelpark.utils.isMobile

@Composable
fun ExpandedPageHeader(
    minHeight: CSSNumericValue<out CSSUnitLengthOrPercentage>,
    content: @Composable () -> Unit,
    iconOnly: Boolean = true,
    modifier: Modifier = Modifier,
) {
    val breakpoint = rememberBreakpoint()
    val colorScheme = PixelParkTheme.colorScheme
    val backgroundTint = colorScheme.primary.with(0.5f)
    val contentColor = colorScheme.onPrimary

    val radius = if (breakpoint.isMobile()) 32.px else 64.px
    val shape = PixelParkRect(top = 0.px, bottom = radius)

    Box(
        modifier = modifier
            .fillMaxWidth()
            .color(contentColor)
            .setVariable(ColorVar, contentColor)
            .background(
                backgrounds = arrayOf(
                    Background.of(
                        image = CSSImage.of(url("/landing-16-9-default.jpg"))
                    ),
                    Background.of(
                        image = BackgroundImage.of(
                            linearGradient(backgroundTint, backgroundTint)
                        )
                    ),

                )
            )
            .backgroundSize(BackgroundSize.Cover)
            .backgroundPosition(BackgroundPosition.of(CSSPosition.Center))
            .pixelParkClip(shape)
    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .minHeight(minHeight)
                .zIndex(0),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            NavHeader(iconOnly, Modifier.fillMaxWidth())

            content()
        }
    }
}