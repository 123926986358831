package pixelpark.model

import kotlinx.serialization.Serializable

@Serializable
data class DogPark(
    val id: String = "",
    val title: String = "",
    val location: ParkLocation = ParkLocation(),
    val parking: ParkParking? = null,
    val metadata: ParkMetadata = ParkMetadata(),
    val features: ParkFeatures = ParkFeatures(),
    val created: Long = 0,
    val localizedTitle: LocalizedString = LocalizedString(),
    val description: LocalizedString? = null
)

@Serializable
data class LocalizedString(
    val base: String = "",
    val translations: List<TranslatedValue> = listOf(),
) {
    @Serializable
    data class TranslatedValue(
        val iso: String = "",
        val value: String = ""
    )
}

@Serializable
enum class ParkSource {
    HundeskoveneScraper
}

@Serializable
data class ParkMetadata(
    val sourceId: String? = null,
    val source: ParkSource = ParkSource.HundeskoveneScraper,
    val description: String? = null,
    val areaSizeHA: Double? = null, // Hectare
    val owner: String? = null,
    val facebookGroup: String? = null,
    val signs: String? = null,
)

@Serializable
data class ParkFeatures(
    val fencing: ParkFencing = ParkFencing.Unfenced,
    val smallDogsArea: Boolean = false,
    val toilet: Boolean = false,
    val water: Boolean = false,
    val trashCan: Boolean = false,
    val agility: Boolean = false,
    val tables: Boolean = false,
    val wetland: Boolean = false,
    val wheelchairFriendly: Boolean = false,
    val walkingImpairedFriendly: Boolean = false,
)

@Serializable
data class ParkParking(
    val location: ParkLocation = ParkLocation(),
    val spaces: Int? = null
)

@Serializable
data class ParkLocation(
    val address: String = "",
    val coordinates: Coordinates? = null,
)

@Serializable
data class Coordinates(
    val latitude: Double = 0.0,
    val longitude: Double = 0.0
)