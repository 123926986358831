package pixelpark.components.widgets

import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.styleModifier
import org.jetbrains.compose.web.css.*

fun Modifier.pixelParkClip(shape: Shape): Modifier = shape.path?.let { path ->
    styleModifier {
        property("clip-path", path.toPathStr())
    }
} ?: this

sealed class Path {
    abstract fun toPathStr(): String
    protected fun Any.toPercentStr() = "${this}%"
    protected fun Pair<Any, Any>.toPercentStr() = "${first}% ${second}%"
}

private fun Pair<Int, Int>.from100() = (100 - first) to (100 - second)
private fun Pair<Float, Float>.from100() = (100f - first) to (100f - second)
private fun Pair<Int, Int>.toFloatPair() = first.toFloat() to second.toFloat()

class InsetPath(
    private val topLeft: Pair<Float, Float>,
    botRight: Pair<Float, Float>,
    private val topLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
    private val topRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    private val bottomRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    private val bottomLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
) : Path() {
    private val botRight = botRight.from100()

    override fun toPathStr(): String {
        val roundnessPart = if (topLeftRadius != 0.px || topRightRadius != 0.px || bottomRightRadius != 0.px || bottomLeftRadius != 0.px) {
            "round $topLeftRadius $topRightRadius $bottomRightRadius $bottomLeftRadius"
        } else {
            ""
        }

        val left = topLeft.first
        val top = topLeft.second
        val right = botRight.first
        val bottom = botRight.second
        val insetPart = when {
            left == top && right == bottom && left == right -> left.toPercentStr()
            left == right && top == bottom -> (top to left).toPercentStr()
            else -> "${top.toPercentStr()} ${right.toPercentStr()} ${bottom.toPercentStr()} ${left.toPercentStr()}"
        }

        return "inset($insetPart$roundnessPart)"
    }
}

interface Shape {
    val path: Path?
}

class RectF(
    val topLeft: Pair<Float, Float>,
    val botRight: Pair<Float, Float>,
    val topLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val topRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val bottomRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val bottomLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
) : Shape {
    constructor() : this(0.px)
    constructor(
        cornerRadius: CSSLengthOrPercentageNumericValue
    ) : this(0f to 0f, 100f to 100f, cornerRadius, cornerRadius, cornerRadius, cornerRadius)

    constructor(
        topBottom: Float,
        leftRight: Float,
        cornerRadius: CSSLengthOrPercentageNumericValue = 0.px
    ) : this(
        leftRight to topBottom,
        (leftRight to topBottom).from100(),
        cornerRadius,
        cornerRadius,
        cornerRadius,
        cornerRadius
    )

    constructor(
        side: Float,
        cornerRadius: CSSLengthOrPercentageNumericValue = 0.px
    ) : this(
        side to side,
        (side to side).from100(),
        cornerRadius,
        cornerRadius,
        cornerRadius,
        cornerRadius
    )

    constructor(
        topLeftRadius: CSSLengthOrPercentageNumericValue,
        topRightRadius: CSSLengthOrPercentageNumericValue,
        bottomRightRadius: CSSLengthOrPercentageNumericValue,
        bottomLeftRadius: CSSLengthOrPercentageNumericValue,
    ) : this(0f to 0f, 100f to 100f, topLeftRadius, topRightRadius, bottomRightRadius, bottomLeftRadius)

    override val path: Path?
        get() = if (topLeft.first != 0f || topLeft.second != 0f
            || botRight.first != 100f || botRight.second != 100f
            || topLeftRadius != 0.px || topRightRadius != 0.px || bottomRightRadius != 0.px || bottomLeftRadius != 0.px
        ) {
            InsetPath(topLeft, botRight, topLeftRadius, topRightRadius, bottomRightRadius, bottomLeftRadius)
        } else {
            null
        }
}

class PixelParkRect(
    val topLeft: Pair<Int, Int>,
    val botRight: Pair<Int, Int>,
    val topLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val topRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val bottomRightRadius: CSSLengthOrPercentageNumericValue = 0.px,
    val bottomLeftRadius: CSSLengthOrPercentageNumericValue = 0.px,
) : Shape by RectF(
    topLeft.toFloatPair(),
    botRight.toFloatPair(),
    topLeftRadius,
    topRightRadius,
    bottomRightRadius,
    bottomLeftRadius
) {
    constructor() : this(0.px)

    constructor(
        cornerRadius: CSSLengthOrPercentageNumericValue
    ) : this(0 to 0, 100 to 100, cornerRadius, cornerRadius, cornerRadius, cornerRadius)

    constructor(
        topBottom: Int,
        leftRight: Int,
        cornerRadius: CSSLengthOrPercentageNumericValue = 0.px
    ) : this(
        leftRight to topBottom,
        (leftRight to topBottom).from100(),
        cornerRadius,
        cornerRadius,
        cornerRadius,
        cornerRadius
    )

    constructor(
        side: Int,
        cornerRadius: CSSLengthOrPercentageNumericValue = 0.px
    ) : this(
        side to side,
        (side to side).from100(),
        cornerRadius,
        cornerRadius,
        cornerRadius,
        cornerRadius
    )

    constructor(
        top: CSSLengthOrPercentageNumericValue,
        bottom: CSSLengthOrPercentageNumericValue,
    ) : this(0 to 0, 100 to 100, top, top, bottom, bottom)
}
