package pixelpark.model

import kotlinx.serialization.Serializable

@Serializable
data class PopulatedPark(
    val parkData: DogPark,
    val point: ParkMapsPoint?,
    val favourite: Boolean,
    val distanceToUserKm: Double?,
) {
    val id: String = parkData.id
    val title: LocalizedString = parkData.localizedTitle
    val location: ParkLocation = parkData.location
    val features: ParkFeatures = parkData.features
    val description: LocalizedString? = parkData.description

    val displayAddress: String get() = location.address
        .split(",")
        .map { it.trim() }
        .filterNot { it.lowercase() in listOf("denmark", "danmark") }
        .joinToString(", ")

//    val coordinates: List<ParkCoordinates> = listOfNotNull(
//        location.coordinates?.let { ParkCoordinates.Park(it) },
//        parkData.parking?.location?.coordinates?.let { ParkCoordinates.Parking(it) },
//    )

    val reviews: List<ParkMapsReview> = point?.reviews?.filter { it.text.isNotEmpty() }
        ?.sortedByDescending { it.time }
        ?: emptyList()

    val imageIds = point?.photoReferences?.takeLast(5) ?: emptyList()

    val rating = point?.stars ?: 0.0

    val facilities: List<ParkFacility> = listOfNotNull(
        ParkFacility.TrashCan.takeIf { features.trashCan },
        ParkFacility.Water.takeIf { features.water },
        ParkFacility.Tables.takeIf { features.tables },
        ParkFacility.Toilet.takeIf { features.toilet },
        ParkFacility.Agility.takeIf { features.agility },
        ParkFacility.Wetland.takeIf { features.wetland },
        ParkFacility.Accessible.takeIf { features.wheelchairFriendly && features.walkingImpairedFriendly },
        ParkFacility.SmallDogsArea.takeIf { features.smallDogsArea },
    )
}

