package pixelpark.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Ul
import pixelpark.PixelParkStrings
import pixelpark.components.layouts.VoresPageLayout
import pixelpark.components.widgets.FormattedText
import pixelpark.localization.strings

// NOTE: Changing this path, requires changes to app store listings and privacy policy
@Page("/data-request")
@Composable
fun DataRequestPage(
    strings: PixelParkStrings = strings()
) {
    VoresPageLayout(subtitle = null) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxHeight()
                .fillMaxWidth()
        ) {
            Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {
                H1 {
                    SpanText(strings.title_data_request)
                }

                P {
                    strings.lines_data_request.forEach {
                        SpanText(it)
                    }
                }

                Ul {
                    val listItems = strings.list_items_data_request
                    listItems.forEachIndexed { index, formattedStrings ->
                        Li {
                            FormattedText(formattedStrings)

                            if (index == listItems.lastIndex) {
                                Ul {
                                    strings.list_sub_items_data_request.forEach {
                                        Li { SpanText(it) }
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }
    }
}
