package pixelpark.model

enum class ParkFacility {
    TrashCan,
    Water,
    Tables,
    Wetland,
    Agility,
    Toilet,
    SmallDogsArea,
    Accessible;
}