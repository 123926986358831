package pixelpark.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import pixelpark.Resource
import pixelpark.Status

@Composable
fun <T> ResourceView(
    resource: Resource<T>,
    modifier: Modifier = Modifier,
    errorTitle: String? = null,
    allowNull: Boolean = true,
    loadingContent: @Composable () -> Unit = { RandomMessageLoadingIndicator() },
    successContent: @Composable (T?) -> Unit,
) {
    Column(modifier) {
        when (resource.status) {
            Status.ERROR -> ErrorContent(title = errorTitle, throwable = resource.error)
            Status.LOADING -> loadingContent()
            Status.SUCCESS -> {
                if (!allowNull && resource.data != null) {
                    ErrorContent(title = errorTitle, throwable = null)
                } else {
                    successContent(resource.data)
                }
            }
        }
    }
}