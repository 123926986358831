package pixelpark.pages.city

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.isExporting
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.dom.P
import pixelpark.PixelParkStrings
import pixelpark.api.ParksApi
import pixelpark.api.RegionApi
import pixelpark.asResource
import pixelpark.components.layouts.ParkListLayout
import pixelpark.components.rememberResourceState
import pixelpark.localization.strings
import pixelpark.model.City
import pixelpark.model.PopulatedPark
import kotlin.math.abs

@Page("/byer/{displayId}/hundeskove")
@Composable
fun CityParksPage(
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()
    val displayId = ctx.route.params.getValue("displayId")
    var city by remember { mutableStateOf<City?>(null) }
    var parks by rememberResourceState<List<PopulatedPark>>()

    LaunchedEffect(displayId) {
        city = RegionApi.getCity(displayId = displayId)
    }
    LaunchedEffect(city) {
        if (ctx.isExporting) return@LaunchedEffect
        parks = ParksApi.searchParksResult(cityName = city?.name ?: return@LaunchedEffect)
            .asResource()
    }

    ParkListLayout(
        title = city?.let {
            strings.city_display_name(it)
        } ?: "",
        description = "",
        parks = parks,
        emptyContent = empty@{
            if (ctx.isExporting) return@empty
            CitySuggestions(city ?: return@empty)
        }
    )
}

@Composable
private fun CitySuggestions(
    originalCity: City,
    strings: PixelParkStrings = strings()
) {
    val ctx = rememberPageContext()
    var cities by remember { mutableStateOf<List<City>>(emptyList()) }
    var parkCountByCityName by remember { mutableStateOf<Map<String, Int>>(emptyMap()) }

    val pageCityRange = originalCity.postNumbers.mapNotNull { it.toIntOrNull() }
        .let { it.min()..it.max() }

    val recommendations = cities
        .filter { parkCountByCityName.getOrElse(it.name) { 0 } > 0 }
        .sortedBy { city ->
            val sortRange = city.postNumbers.mapNotNull { it.toIntOrNull() }
                .let { it.min()..it.max() }

            val delta: Int = listOf(
                abs(sortRange.first - pageCityRange.first),
                abs(sortRange.last - pageCityRange.first),
                abs(sortRange.last - pageCityRange.last),
                abs(sortRange.first - pageCityRange.last)
            ).min()

            return@sortedBy delta
        }
        .take(5)

    LaunchedEffect(Unit) {
        launch {
            cities = RegionApi.getCities()
        }
        launch {
            parkCountByCityName = ParksApi.getParkCountByCityNameResult().getOrDefault(emptyMap())
        }
    }

    Column(Modifier.fillMaxWidth(), horizontalAlignment = Alignment.Start) {

        strings.empty_city_list_lines(originalCity).forEach {
            P { SpanText(it) }
        }

        Box(Modifier.size(1.cssRem))

        CityListLayout(
            recommendations, parkCountByCityName
        )
    }
}
